<template>
  <KTPortlet title="View/Edit template" class="noBodyPad">
    <template slot="toolbar">
      <slot name="saveModal" data-app="templateAp">
        <!-- <b-form-select
          v-model="selectedType"
          :options="drafts"
          style="margin-right: 10px;"
          @change="$emit('changeDraft', selectedDraft);"
        ></b-form-select>-->
        <!-- <v-autocomplete
          v-if="!$tools.isNullOrUndefined(drafts)"
          :items="drafts"
          item-text="text"
          item-value="value"
          v-model="selectedDraft"
          style="margin-right: 10px;"
          xplaceholder="Start typing to Search"
          @change="$emit('changeDraft', selectedDraft);"
        ></v-autocomplete>-->
        <b-form-select
          v-if="!$tools.isNullOrUndefined(drafts) && drafts.length > 1"
          v-model="selectedDraft"
          :options="drafts"
          style="margin-right: 10px;"
          @change="$emit('changeDraft', selectedDraft)"
        ></b-form-select>
        <template v-if="canDeploy">
          <b-button variant="primary" @click="$emit('deploy', selectedDraft)"
            >DEPLOY</b-button
          >
          <b-button
            style="margin-left: 10px;"
            @click="$emit('delete', selectedDraft)"
            variant="danger"
            >DELETE</b-button
          >
        </template>
        <b-button
          v-else-if="hasChangesToSave"
          variant="success"
          @click="$emit('save', 1)"
          >SAVE</b-button
        >
        <b-button
          v-else
          variant="info"
          style="margin-left: 10px;"
          @click="$emit('newTemplate')"
          >New<span style="opacity: 0;">_</span>Template</b-button
        >
      </slot>
    </template>
    <template slot="body">
      <Loader v-show="loading == true" :inContent="true">Loading...</Loader>
      <TemplatesItemDefaultMenuItem
        v-show="loading != true"
        v-if="menuItems !== null"
        :menuItems="menuItems"
        :stylez="{
          def: { horizontal: true },
          bia: {
            vertical: true,
            headerStyle: 'background: var(--dark); color: #fff;'
          },
          extl: {
            vertical: true,
            headerStyle: 'background: var(--dark); color: #fff;'
          }
        }"
      >
        <div
          v-for="menuItem in menuItems"
          v-bind:key="menuItem.id"
          :slot="`item-${menuItem.id}`"
          slot-scope="slotScope"
          style="padding: 10px;"
        >
          <MenuData
            :menuItem="slotScope.value"
            :template="template"
            :templateItem="
              $tools.GetValueFromObjectBasedOnStringPath(
                template,
                menuItem.id.replace(/\//g, '.')
              )
            "
            :templateInfo="templateInfo"
            :type="type"
            :layout="layout"
            :getDataForPath="getDataForPath"
            @updateTemplate="(a, b, c) => $emit('updateTemplate', a, b, c)"
            @addConfigChangeLog="a => $emit('addConfigChangeLog', a)"
            @addItem="(a, b, c) => $emit('addItem', a, b, c)"
            @editItem="(a, b, c) => $emit('editItem', a, b, c)"
            @removeItem="(a, b, c) => $emit('removeItem', a, b, c)"
            @saveConfigEdit="
              (a, b, c, d) => $emit('saveConfigEdit', a, b, c, d)
            "
            @saveOptionChange="(a, b, c, d) => saveOptionChange(a, b, c, d)"
          />
        </div>
      </TemplatesItemDefaultMenuItem>
    </template>
  </KTPortlet>
</template>

<script>
import { mapGetters } from "vuex";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import Loader from "@/views/partials/content/Loader.vue";
//import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
//import Swal from "sweetalert2";
//import TLib from "./lib";
import MenuTemplate from "./templates.item.default.menuItem";
import MenuData from "./templates.item.default.data";

export default {
  name: "TemplatesItemDefault",
  props: [
    "template",
    "templateInfo",
    "type",
    "layout",
    "getDataForPath",
    "updateTemplate",
    "loading",
    "canDeploy",
    "hasChangesToSave",
    "menuItems"
  ],
  components: {
    KTPortlet,
    TemplatesItemDefaultMenuItem: MenuTemplate,
    MenuData: MenuData,
    Loader
    //X_Mikrotik_Routerboard: X_Mikrotik_Routerboard
  },
  watch: {},
  data() {
    return {
      selectedDraft: null,
      drafts: null,
      templates: null,
      types: null,
      workingTemplate: null,
      workingType: null,
      workingComponent: null
    };
  },
  methods: {
    removeItem(id, doc) {
      let refItem = id.replace(/\//g, ".");
      if (!refItem.endsWith("._list")) refItem = `${refItem}._list`;
      let listOfData = this.getDataForPath(refItem, undefined, undefined, -1);
      let origStr = JSON.stringify(doc);
      for (let i = 0; i < listOfData.length; i++) {
        if (JSON.stringify(listOfData[i]) == origStr) {
          listOfData.splice(i, 1);
          break;
        }
      }
      this.updateTemplate(refItem, listOfData);
    },
    addChange(id, doc) {
      let refItem = id.replace(/\//g, ".");
      if (!refItem.endsWith("._list")) refItem = `${refItem}._list`;
      let listOfData = this.getDataForPath(refItem, undefined, undefined, -1);
      listOfData.push(doc);
      this.updateTemplate(refItem, listOfData);
    },
    saveChange(id, doc, origDoc) {
      let refItem = id.replace(/\//g, ".");
      if (!refItem.endsWith("._list")) refItem = `${refItem}._list`;
      let listOfData = this.getDataForPath(refItem, undefined, undefined, -1);
      let origStr = JSON.stringify(origDoc);
      for (let i = 0; i < listOfData.length; i++) {
        if (JSON.stringify(listOfData[i]) == origStr) {
          listOfData[i] = doc;
          break;
        }
      }
      this.updateTemplate(refItem, listOfData);
    },
    saveOptionChange(id, doc) {
      //console.log("saveOptionChange");
      let refItem = id.replace(/\//g, ".");
      /*this.$parent.$data.changesList.push({
        time: new Date().getTime(),
        text: `Changed ${this.editItemRefObj.options.id} (${changes.join(
          ", "
        )}) -> (${changesTo.join(", ")})`,
        valueOld: this.editItemRefObj.value,
        value: this.editItemObj.value,
        refProps: this.editItemRefObj.options.props,
        menuObj: this.editItemRefObj.options.menuObj,
        viewOnly: this.editItemRefObj.options.viewOnly,
        revert: "change"
      });*/
      this.updateTemplate(refItem, doc, false);
    },
    SetComponent() {
      switch (this.workingType.name) {
        case "MIKROTIK":
          {
            switch (this.workingType.make) {
              case "ROUTERBOARD":
                {
                  this.$data.workingComponent = "X_Mikrotik_Routerboard";
                }
                break;
            }
          }
          break;
      }
    },
    SetType() {
      for (let tType of this.types)
        if (tType.id === this.workingTemplate.routerTypeId) {
          this.$data.workingType = tType;

          this.SetComponent();
          return;
        }

      return this.$router.push("/Networks/Templates");
    },
    SetTemplate() {
      for (let tTemplate of this.templates)
        if (tTemplate.id === this.$route.params.templateId) {
          this.$data.workingTemplate = tTemplate;
          /*this.$store.dispatch(SET_BREADCRUMB, [
            { title: "BetterNetworks" },
            { title: "Templates" },
            { title: tTemplate.name }
          ]);*/
          this.SetType();
          return;
        }

      return this.$router.push("/Networks/Templates");
    },
    reRender() {
      let self = this;
      self.$data.drafts = null;
      self.$nextTick(() => {
        self.$data.selectedDraft = self.$route.params.draftId || null;
        /*self.$store.dispatch(SET_BREADCRUMB, [
          { title: "BetterNetworks" },
          { title: "Templates" },
          { title: "View/Edit" }
        ]);*/
        let drafts = [{ text: "NEW CONFIG", value: null }];
        for (let draft of self.templateInfo.drafts || []) {
          if (draft.deployed === true || draft.deleted === true) continue;
          drafts.push({ text: draft.name, value: draft.version });
        }
        self.$data.drafts = drafts;
      });
    }
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    config() {
      return this.layoutConfig();
    }
  },
  mounted() {
    //console.error('DEFAULT ITEM')
    return;
    //this.reRender();
    /*
    let self = this;
    TLib.getTypes(self, self.$route.params.serviceId)
      .then(typex => {
        self.$data.types = typex;
        TLib.getTemplates(self, self.$route.params.serviceId)
          .then(data => {
            self.$data.templates = data;
            self.SetTemplate();
          })
          .catch(x => {
            self.$log.error(x);
            console.error("Error loading content");
          });
      })
      .catch(x => {
        self.$log.error(x);
        console.error("Error loading content");
      });*/
  }
};
</script>
