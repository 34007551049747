<template>
  <div class="tidd-page" v-if="!$tools.isNullOrUndefined(menuItem._list)">
    <div v-if="listMenuItems.length === 0">
      ! List problem ! Contact Support !
    </div>
    <template v-else>
      <div class="dropdown dropdown-inline">
        <button
          type="button"
          cflass="btn btn-clean btn-sm btn-icon btn-icon-md"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i class="flaticon2-settings"></i> Change layout
        </button>
        <div
          class="
            dropdown-menu dropdown-menu-left dropdown-menu-fit dropdown-menu-md
          "
        >
          <div style="padding: 20px">
            <b-form-group label="Change what columns are shown:">
              <b-form-checkbox-group
                stacked
                v-model="customMenuStyle"
                :options="customMenuOptions"
              ></b-form-checkbox-group>
            </b-form-group>
          </div>
          <hr />
          <button
            type="button"
            cflass="btn btn-clean btn-sm btn-icon btn-icon-md"
            style="margin-left: 20px; margin-bottom: 15px"
            @click="ResetCustomMenu()"
          >
            RESET COLUMNS
          </button>
        </div>
      </div>
      <button
        v-if="(menuItem._options || {}).addNewItemDisabled !== true"
        type="button"
        cflass="btn btn-clean btn-sm btn-icon btn-icon-md"
        style="margin-left: 20px"
        aclick="$emit('updateTemplate', menuItem.id, [{name: 'A', type:'B', comment: 'this is a comment'}])"
        @click="AddNewItem"
      >
        <i class="flaticon2-add"></i> Add New Record
      </button>
      <b-table-simple responsive>
        <b-thead>
          <b-tr>
            <b-th v-if="(menuItem._options || {}).orderedList === true">#</b-th>
            <b-th
              v-for="row in listMenuItems"
              v-bind:key="row"
              :sticky-column="menuItem._list[row].keyProp === true"
            >
              {{ menuItem._list[row].name }}
              <v-chip
                small
                v-if="getAdditionalInfo(row) !== null"
                class="ma-1"
                :style="`background: var(--dark); color: #fff; margin-left: 10px;`"
                >{{ getAdditionalInfo(row).value.name }}</v-chip
              >
            </b-th>
          </b-tr>
        </b-thead>
        <component
          :is="
            (menuItem._options || {}).orderedList !== true
              ? 'b-tbody'
              : 'draggable'
          "
          v-model="dataListArr"
          tag="b-tbody"
          @end="dataOptChange"
        >
          <b-tr
            v-for="(row, index) in dataListArr"
            v-bind:key="`${index}${getListRowItemKey(row)}`"
            vf-bind:data-id="index"
          >
            <b-th
              v-if="(menuItem._options || {}).orderedList === true"
              style="cursor: pointer"
            >
              <i class="flaticon2-sort sortIcon"></i>
              <span style="margin-left: 3px">{{ index + 1 }}</span>
            </b-th>
            <component
              v-for="menuRow in listMenuItems"
              v-bind:key="menuRow"
              :is="menuItem._list[menuRow].keyProp === true ? 'b-th' : 'b-td'"
              :sticky-column="menuItem._list[menuRow].keyProp === true"
            >
              <span
                v-if="
                  !$tools.isNullOrUndefined(menuItem._list[menuRow].property)
                "
                >{{
                  (row.props || row)[menuItem._list[menuRow].property]
                }}</span
              >
              <span
                v-else-if="menuItem._list[menuRow].type.indexOf('multiple')"
                >{{
                  $tools.isArray(row[menuRow])
                    ? (row.props || row)[menuRow].join(", ")
                    : (row.props || row)[menuRow] || ""
                }}</span
              >
              <span v-else>{{ (row.props || row)[menuRow] }}</span>
              <v-chip
                small
                v-if="!$tools.isNullOrUndefined(getRowMetaChip(row, menuRow))"
                class="ma-1"
                :style="`background: var(--dark); color: #fff; margin-left: 10px;`"
                >{{ getRowMetaChip(row, menuRow) }}</v-chip
              >
            </component>
            <b-td>
              <b-button variant="info" @click="EditItem(row)"
                >View{{
                  (!$tools.isNullOrUndefined(menuItem._options) &&
                    menuItem._options.editItemDisabled === true) ||
                  row._ref === "hierarchical"
                    ? ""
                    : "/Edit"
                }}</b-button
              >
              <b-button
                v-if="
                  !(
                    (!$tools.isNullOrUndefined(menuItem._options) &&
                      (menuItem._options.removeItemDisabled === true ||
                        menuItem._options.editItemDisabled === true)) ||
                    row._ref === 'hierarchical'
                  )
                "
                variant="danger"
                @click="RemoveItem(row)"
                style="margin-left: 10px"
                >Remove</b-button
              >
            </b-td>
          </b-tr>
        </component>

        <b-tbody>
          <!-- <template
            v-if="!$tools.isNullOrUndefined(menuItem._defaults) && $tools.isArray(menuItem._defaults)"
          >-->
          <b-tr
            v-for="(row, index) in getDataForPath(
              menuItem.id,
              undefined,
              undefined,
              1
            )"
            v-bind:key="`d${index}${getListRowItemKey(row)}`"
          >
            <b-th v-if="(menuItem._options || {}).orderedList === true">{{
              (listMenuItems || []).length + index + 1
            }}</b-th>
            <component
              v-for="menuRow in listMenuItems"
              v-bind:key="menuRow"
              :is="menuItem._list[menuRow].keyProp === true ? 'b-th' : 'b-td'"
              :sticky-column="menuItem._list[menuRow].keyProp === true"
            >
              <span
                v-if="
                  !$tools.isNullOrUndefined(menuItem._list[menuRow].property)
                "
                >{{ row[menuItem._list[menuRow].property] }}</span
              >
              <span
                v-else-if="menuItem._list[menuRow].type.indexOf('multiple')"
                >{{
                  $tools.isArray(row[menuRow])
                    ? row[menuRow].join(", ")
                    : row[menuRow] || ""
                }}</span
              >
              <span v-else>{{ row[menuRow] }}</span>
              <v-chip
                small
                v-if="!$tools.isNullOrUndefined(getRowMetaChip(row, menuRow))"
                class="ma-1"
                :style="`background: var(--dark); color: #fff; margin-left: 10px;`"
                >{{ getRowMetaChip(row, menuRow) }}</v-chip
              >
            </component>
            <b-td>
              <b-button variant="info" @click="EditItem(row, true)"
                >View</b-button
              >
            </b-td>
          </b-tr>
          <!-- </template> -->
        </b-tbody>
      </b-table-simple>
    </template>
  </div>
  <div v-else-if="!$tools.isNullOrUndefined(menuItem._config)">
    <DataConfig
      @blur="configBlur"
      :value="ConfigValue"
      :options="menuItem._config"
      :getDataForPath="getDataForPath"
    />
    <!-- <b-row v-for="row in Object.keys(menuItem._options)" v-bind:key="row">
      <b-col>
        <b-form-group
          :label="menuItem._options[row].name"
          :description="menuItem._options[row].description"
        >
          <template v-if="menuItem._options[row].type === 'boolean'">
            <b-checkbox dv-model="form.autoRegSearched" />
          </template>
          <template v-else>
            <b-form-input
              dfv-model="form.domain"
              :type="menuItem._options[row].type"
              :required="menuItem._options[row].required"
              :placeholder="menuItem._options[row].placeholder"
            ></b-form-input>
          </template>
        </b-form-group>
      </b-col>
    </b-row>-->
    <!-- <button @click="$emit('saveConfigEdit', menuId, ConfigValueRef, ConfigValue, menuItem._config)">SAVE</button> -->
  </div>
  <div v-else>! Unknown Config ! Contact Support !</div>
</template>

<script>
import { mapGetters } from "vuex";
//import Loader from "@/views/partials/content/Loader.vue";
//import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
//import Swal from "sweetalert2";
//import TLib from "./lib";
import DataConfig from "./templates.item.default.data.config";

export default {
  name: "TemplatesItemDefaultData",
  props: [
    "template",
    "templateItem",
    "templateInfo",
    "type",
    "layout",
    "menuItem",
    "getDataForPath",
  ],
  components: {
    DataConfig: DataConfig,
  },
  watch: {
    customMenuStyle(from, to) {
      if (from.length === to.length) return;
      this.setSavedMenuConfig();
      if (this.$tools.isNullOrUndefined(this.menuItem._list)) return [];
      let listOfMenuItems = [];

      if (this.customMenuStyle.length > 0) {
        for (let menuItem of Object.keys(this.menuItem._list)) {
          if (menuItem.indexOf("_") === 0) continue;
          if (this.customMenuStyle.indexOf(menuItem) >= 0) {
            listOfMenuItems.push(menuItem);
          }
        }
      }

      this.$data.listMenuItems = listOfMenuItems;

      if (listOfMenuItems.length === 0) {
        let savedConfig = window.sessionStorage.getItem(this.storeSaveKey);
        if (!this.$tools.isNullOrUndefined(savedConfig)) {
          this.ResetCustomMenu();
        }
      }
    },
    getDataList: {
      deep: true,
      handler() {
        this.$data.dataListArr = this.getDataList;
      },
    },
  },
  data() {
    return {
      customMenuStyle: [],
      listMenuItems: [],
      menuId: null,
      ConfigValueRef: null,
      ConfigValue: null,
      dataListArr: [],
      dragDropOptions: {
        dropzoneSelector: "tbody",
        draggableSelector: "tr",
        handlerSelector: null,
        reactivityEnabled: true,
        multipleDropzonesItemsDraggingEnabled: false,
        showDropzoneAreas: true,
      },
    };
  },
  methods: {
    getRowMetaChip(row, menuRow) {
      if (this.$tools.isNullOrUndefined(row)) return null;
      if (this.$tools.isNullOrUndefined(menuRow)) return null;
      let addItem = this.getAdditionalInfo(menuRow);
      if (this.$tools.isNullOrUndefined(addItem)) return null;
      if (this.$tools.isNullOrUndefined(addItem.id)) return null;
      if (!this.$tools.isNullOrUndefined(row[addItem.id]))
        return row[addItem.id] || null;
      if (this.$tools.isNullOrUndefined(row.props)) return null;
      if (!this.$tools.isNullOrUndefined(row.props[addItem.id]))
        return row.props[addItem.id] || null;
      return null;
    },
    dataOptChange() {
      this.$emit(
        "updateTemplate",
        this.menuItem.id + "._list",
        this.dataListArr
      );
      this.$emit("addConfigChangeLog", {
        text: `Changed order ${this.menuItem.id}`,
        value: null,
        revert: "orderChange",
        refProps: this.menuItem._list,
        menuObj: this.menuItem,
      });
    },
    configBlur() {
      this.$emit("saveOptionChange", this.menuId, this.ConfigValue);
    },
    ResetCustomMenu() {
      window.sessionStorage.removeItem(this.storeSaveKey);
      this.$data.customMenuStyle = [];
    },
    RemoveItem(row) {
      this.$emit(
        "removeItem",
        {
          id: this.menuItem.id,
          name: this.menuItem.actualName || this.menuItem.name,
          value: row,
        },
        this.menuItem._list
      );
    },
    AddNewItem() {
      this.$emit(
        "addItem",
        {
          id: this.menuItem.id,
          name: this.menuItem.actualName || this.menuItem.name,
        },
        this.menuItem._list
      );
    },
    EditItem(row, viewOnlyForced = false) {
      this.$emit(
        "editItem",
        {
          id: this.menuItem.id,
          name: this.menuItem.actualName || this.menuItem.name,
          value: row,
        },
        this.menuItem._list,
        viewOnlyForced ||
          (!this.$tools.isNullOrUndefined(this.menuItem._options) &&
            this.menuItem._options.editItemDisabled === true) ||
          row._ref === "hierarchical"
      );
    },
    getListRowItemKey(obj) {
      /*let thisKeyParams = [];
      for (let menuItem of Object.keys(this.menuItem._list)) {
        if (
          this.menuItem._list[menuItem].required === true ||
          this.menuItem._list[menuItem].keyProp === true
        )
          thisKeyParams.push(menuItem);
      }
      if (thisKeyParams.length === 0) {
        for (let menuItem of Object.keys(this.menuItem._list)) {
          thisKeyParams.push(menuItem);
        }
      }*/
      let newDefinedKey = [];
      for (let itemName of Object.keys(this.menuItem._list)) {
        newDefinedKey.push(
          `${itemName}`.replace(/\W/g, "") +
            "" +
            `${obj[itemName]}`.replace(/\W/g, "")
        );
      }
      return newDefinedKey.join("");
    },
    getAdditionalInfo(field) {
      for (let menuItem of Object.keys(this.menuItem._list)) {
        if (
          this.menuItem._list[menuItem].additionalInfoFor === field &&
          this.customMenuStyle.indexOf(menuItem) < 0
        )
          return {
            id: menuItem,
            value: this.menuItem._list[menuItem],
          };
      }
      return null;
    },
    getSavedMenuConfig() {
      if (this.$tools.isNullOrUndefined(this.menuItem._list)) return [];
      //this.$data.customMenuStyle = [];
      // let savedConfig = window.localStorage.getItem(
      //   `bn-tpl-col-config-${this.templateInfo.id}-${this.menuItem.id}`
      // );
      let savedConfig = window.sessionStorage.getItem(this.storeSaveKey);
      if (!this.$tools.isNullOrUndefined(savedConfig))
        return (this.$data.customMenuStyle = JSON.parse(savedConfig));

      let custoList = [];
      for (let menuItem of Object.keys(this.menuItem._list)) {
        if (
          this.menuItem._list[menuItem].displayInList === true ||
          this.menuItem._list[menuItem].keyProp === true ||
          this.menuItem._list[menuItem].required === true
        )
          custoList.push(menuItem);
      }
      if (custoList.length === 0)
        custoList.push(
          this.menuItem._list[Object.keys(this.menuItem._list)[0]]
        );
      if (custoList.length !== this.customMenuStyle.length)
        this.$data.customMenuStyle = custoList;
    },
    setSavedMenuConfig() {
      if (this.customMenuStyle.length == 0) {
        window.sessionStorage.removeItem(this.storeSaveKey);
        return this.getSavedMenuConfig();
      }
      window.sessionStorage.setItem(
        this.storeSaveKey,
        JSON.stringify(this.customMenuStyle)
      );
    },
  },
  computed: {
    getDataList() {
      if (
        !this.$tools.isNullOrUndefined(this.menuItem._options) &&
        !this.$tools.isNullOrUndefined(this.menuItem._options.referenceData)
      ) {
        let outDataList = [];

        let referencess = this.menuItem._options.referenceData.split(",");
        if (
          !this.$tools.isNullOrUndefined(
            this.menuItem._options.referenceOptions
          ) &&
          !this.$tools.isNullOrUndefined(
            this.menuItem._options.referenceOptions.addPaths
          )
        ) {
          for (let line of this.menuItem._options.referenceOptions.addPaths)
            referencess.push(line);
        }
        for (let referencc of referencess) {
          let foundData = this.getDataForPath(
            referencc,
            undefined,
            undefined,
            -1
          );
          if (!this.$tools.isArray(foundData)) continue;
          for (let founDat of foundData) {
            outDataList.push(founDat);
          }
        }
        if (
          !this.$tools.isNullOrUndefined(
            this.menuItem._options.referenceOptions
          ) &&
          !this.$tools.isNullOrUndefined(
            this.menuItem._options.referenceOptions.removePaths
          )
        ) {
          for (let ix = 0; ix < outDataList.length; ix++) {
            if (
              this.menuItem._options.referenceOptions.removePaths.indexOf(
                outDataList[ix]._GDKEY
              ) >= 0
            ) {
              outDataList.splice(ix, 1);
              ix = 0;
            }
          }
        }

        return outDataList;
      }
      // getDataForPath : : "x._list"
      let idPath = this.menuItem.id.replace(/\//g, ".");
      if (!idPath.endsWith("._list")) idPath = `${idPath}._list`;
      return this.getDataForPath(idPath, undefined, undefined, -1);

      /*let thisList = this.templateItem || [];

      if (!this.$tools.isNullOrUndefined(this.type)) {
        if (!this.$tools.isNullOrUndefined(this.type.defaults)) {
          let templateData = this.$tools.GetValueFromObjectBasedOnStringPath(
            this.type.defaults,
            this.menuItem.id.replace(/\//g, ".")
          );
          if (!this.$tools.isNullOrUndefined(templateData)) {
            let newData = this.$tools.mergeObjects(
              templateData._list,
              JSON.parse(JSON.stringify(thisList))
            );
            thisList = newData;
          }
        }
      }

      return thisList;*/
    },
    storeSaveKey() {
      return `bn-tpl-col-config-${this.templateInfo.type.brand}-${this.templateInfo.type.make}-${this.menuItem.id}`;
    },
    customMenuOptions() {
      if (this.$tools.isNullOrUndefined(this.menuItem._list)) return [];

      let listOfMenuItems = [];

      if (listOfMenuItems.length === 0) {
        for (let menuItem of Object.keys(this.menuItem._list)) {
          if (menuItem.indexOf("_") === 0) continue;
          listOfMenuItems.push({
            text: this.menuItem._list[menuItem].name,
            value: menuItem,
          });
        }
      }

      return listOfMenuItems;
    },

    ...mapGetters(["layoutConfig"]),

    config() {
      return this.layoutConfig();
    },
  },
  mounted() {
    this.$data.menuId = this.menuItem.id.replace(/\//g, ".");
    this.getSavedMenuConfig();

    if (!this.$tools.isNullOrUndefined(this.menuItem._config)) {
      let tData = this.getDataForPath(
        this.$data.menuId,
        undefined,
        undefined,
        -1
      );
      this.$data.ConfigValue = tData;
      this.$data.ConfigValueRef = tData;
    }
    this.$data.dataListArr = this.getDataList;
  },
};
</script>

<style>
.tidd-page .item-dropzone-area {
  height: 2rem;
  background: var(--light);
  opacity: 0.8;
  animation-duration: 0.5s;
  animation-name: tidd-nodeInserted;
}
@keyframes tidd-nodeInserted {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 0.8;
  }
}
.tidd-page tbody tr[aria-grabbed="true"] {
  background: #5cc1a6;
  color: #fff;
}
</style>
<style scoped>
table .sortIcon {
  opacity: 0;
  animation-duration: 0.5s;
  animation-name: tidd-sortIconAnimation;
}
table:hover .sortIcon {
  opacity: 1;
  animation-duration: 0.5s;
  animation-name: tidd-sortIconAnimationUp;
}
@keyframes tidd-sortIconAnimation {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes tidd-sortIconAnimationUp {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
