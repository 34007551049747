<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <div v-for="group in Object.keys(groups)" v-bind:key="group" style="padding-bottom: 20px;">
      <h5 v-if="group !== '_default'">{{ group }}</h5>
      <b-row v-for="row in Object.keys(groups[group])" v-bind:key="row">
        <b-col v-if="hideFields.indexOf(row) < 0">
          <ConfigValue v-if="!$tools.isNullOrUndefined(data)" :getDataForPath="getDataForPath" :field="options[row]"
            @blur="fieldBlur" :readonly="readonly" v-model="(data.props || data)[row]" @change="collateRows" />
          <div v-else>Field error</div>
        </b-col>
      </b-row>
    </div>
    <template v-if="$refs.form !== undefined">
      <slot name="form-extras" :form="$refs.form">

      </slot>
    </template>
  </v-form>
</template>

<script>
import { mapGetters } from "vuex";
//import Loader from "@/views/partials/content/Loader.vue";
//import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
//import Swal from "sweetalert2";
//import TLib from "./lib";
import ConfigValue from "./templates.item.default.data.config.value";

export default {
  name: "TemplatesItemDefaultDataConfig",
  props: ["options", "value", "getDataForPath", "readonly"],
  components: {
    ConfigValue: ConfigValue
  },
  watch: {
    value() {
      this.$data.data = this.value;
      //this.collateRows();
    },
    data: {
      handler() {
        this.$emit("input", this.data);
        //this.collateRows();
      },
      deep: true
    }
  },
  data() {
    return {
      data: null,
      hideFields: [],
      hideFieldsAsStr: "",
      colateRunning: false,
      valid: true
    };
  },
  methods: {
    fieldBlur() {
      if (this.readonly !== true)
        this.validate();
      this.$emit('blur');
    },
    validate() {
      this.$refs.form.validate();
      return this.valid;
    },
    collateRows() {
      if (this.colateRunning) return;
      this.$data.colateRunning = true;
      setTimeout(this.actCollateRows, 100);
    },
    actCollateRows() {
      let shouldBeHidden = [];
      if (
        this.$tools.isNullOrUndefined(this.value) ||
        this.$tools.isNullOrUndefined(this.options)
      )
        return;
      for (let groupKey of Object.keys(this.groups)) {
        let groupItem = this.groups[groupKey];
        for (let fieldKey of Object.keys(groupItem)) {
          let thisState = this.showRow(fieldKey);
          if (thisState !== null) {
            if (!thisState) {
              shouldBeHidden.push(fieldKey);
            }
          }
        }
      }

      if (shouldBeHidden.join("") === this.hideFieldsAsStr) {
        return (this.$data.colateRunning = false);
      }
      this.$data.hideFieldsAsStr = shouldBeHidden.join("");
      this.$data.hideFields = shouldBeHidden;
      this.$data.colateRunning = false;
    },
    showRow(row) {
      if (
        !this.$tools.isNullOrUndefined(this.options[row]) &&
        !this.$tools.isNullOrUndefined(this.options[row].conditions)
      ) {
        let okay = true;
        for (let cond of this.options[row].conditions) {
          let valueToCheck = this.value[cond.key];
          let thisValueToCheck = cond.value;
          if (thisValueToCheck == "undefined") thisValueToCheck = undefined;
          if (thisValueToCheck == "null") thisValueToCheck = null;
          let matched = false;
          switch (cond.eq) {
            case "==":
              matched = thisValueToCheck == valueToCheck;
              break;
            case "!=":
              matched = thisValueToCheck != valueToCheck;
              break;
            case "===":
              matched = thisValueToCheck === valueToCheck;
              break;
            case "!==":
              matched = thisValueToCheck !== valueToCheck;
              break;
            case ">":
              matched = thisValueToCheck > valueToCheck;
              break;
            case ">=":
              matched = thisValueToCheck >= valueToCheck;
              break;
            case "<":
              matched = thisValueToCheck < valueToCheck;
              break;
            case "<=":
              matched = thisValueToCheck <= valueToCheck;
              break;
            case "indexOf>0":
              matched = valueToCheck.indexOf(thisValueToCheck) > 0;
              break;
            case "indexOf>1":
              matched = valueToCheck.indexOf(thisValueToCheck) > 1;
              break;
            case "indexOf=0":
              matched = valueToCheck.indexOf(thisValueToCheck) == 0;
              break;
            case "indexOf<0":
              matched = valueToCheck.indexOf(thisValueToCheck) < 0;
              break;
          }
          if (!matched) {
            okay = false;
            break;
          }
        }
        return okay;
      }
      return null;
    },
    AddNewItem() {
      /*let tempArr = JSON.parse(JSON.stringify(this.templateItem || []));
      tempArr.push({
        address: "172.16.0.1/29",
        disabled: true,
        interface: "sfp-sfpplus1",
        network: "172.16.0.0"
      });
      this.$emit("updateTemplate", this.menuItem.id, tempArr);*/
      this.$emit(
        "addItem",
        {
          id: this.menuItem.id,
          name: this.menuItem.actualName || this.menuItem.name
        },
        this.menuItem._list
      );
    }
  },
  computed: {
    groups() {
      let arrOfGroups = {
        _default: {}
      };

      for (let item of Object.keys(this.options)) {
        arrOfGroups[this.options[item]._group || "_default"] =
          arrOfGroups[this.options[item]._group || "_default"] || {};
        arrOfGroups[this.options[item]._group || "_default"][
          item
        ] = this.options[item];
      }
      return arrOfGroups;
    },
    ...mapGetters(["layoutConfig"]),

    config() {
      return this.layoutConfig();
    }
  },
  mounted() {
    this.$data.data = this.value;
    this.collateRows();
  }
};
</script>
