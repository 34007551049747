<template>
  <div v-if="
    !$tools.isNullOrUndefined(field) && !$tools.isNullOrUndefined(field.type)
  " :data-app="`configMenuItem-${ field.name }`">
    <template v-if="field.type === 'readonly'">
      <v-text-field v-model="data" type="text" :prefix="field.prefix" :suffix="field.suffix"
        :prepend-icon="field.prependIcon" :prepend-inner-icon="field.prependInnerIcon" :append-icon="field.appendIcon"
        :append-outer-icon="field.appendOuterIcon" :counter="field.counter" :messages="field.messages"
        :persistent-hint="field.persistentHint" :placeholder="field.placeholder" :readonly="true"
        :hint="field.description" :label="`${ field.name }${ field.required === true ? ' *' : '' }`"></v-text-field>
    </template>
    <template v-else-if="['roboolean', 'boolean'].indexOf(field.type) >= 0">
      <v-checkbox  v-model="data" @blur="$emit('blur')" :readonly="field.type === 'roboolean' || readonly"
        :label="`${ field.name }${ field.required === true ? ' *' : '' }`"></v-checkbox>
    </template>
    <template v-else-if="field.type.indexOf('multiple') >= 0">
      <div v-if="field.type.indexOf('rmultiple') === 0">
        <b-form-group style="margin-top: 2rem">
          <div slot="label">
            {{ field.name }}
            <v-btn text small @click="$tools.isArray(data) ? data.push('') : (data = [''])"
              v-if="field.readonly !== true && readonly !== true">
              <i class="flaticon2-plus-1 v-size--med"></i>
            </v-btn>
          </div>
          <div v-if="
            $tools.isNullOrUndefined(data) ||
            !$tools.isArray(data) ||
            data.length === 0
          "></div>
          <div v-else v-for="(row, i) in data || []" v-bind:key="row">
            <div style="display: inline-block; width: calc(100% - 42px)"
              :data-app="`configMenuItem-${ field.name }-block1`">
              <!-- <v-autocomplete
                @blur="$emit('blur')"
                :items="referenceOptions"
                :filter="referenceFilter"
                :prefix="field.prefix"
                :suffix="field.suffix"
                :prepend-icon="field.prependIcon"
                :prepend-inner-icon="field.prependInnerIcon"
                :append-icon="field.appendIcon"
                :append-outer-icon="field.appendOuterIcon"
                :counter="field.counter"
                :messages="field.messages"
                :readonly="field.readonly || readonly"
                :persistent-hint="field.persistentHint"
                :placeholder="field.placeholder"
                item-text="name"
                item-value="value"
                v-model="data[i]"
                xplaceholder="Start typing to Search"
              ></v-autocomplete> -->
              <!-- <v-select
                @blur="$emit('blur')"
                :prefix="field.prefix"
                :suffix="field.suffix"
                :placeholder="field.placeholder"
                :readonly="field.readonly || readonly"
                v-model="data[i]"
                :label="`${field.name}${field.required === true ? ' *' : ''}`"
                :items="referenceOptions"
                item-text="name"
                item-value="value"
              ></v-select> -->
              <label>{{ field.name }}
                {{ field.required === true ? " *" : "" }}</label>
              <b-form-select v-model="data[i]" class="mb-3" :readonly="field.readonly || readonly">
                <option :value="null" v-if="field.placeholder">
                  {{ field.placeholder }}
                </option>
                <option v-for="item in referenceOptions" v-bind:key="`${ item.name }:${ item.value }`" :value="item.value">
                  {{ item.name }}
                </option>
              </b-form-select>
            </div>
            <div style="display: inline-block; margin-left: 5px" v-if="field.readonly !== true && !readonly">
              <b-btn @click="data.splice(i, 1)" small text>X</b-btn>
            </div>
          </div>
        </b-form-group>
      </div>
      <div v-else>
        <div :style="
          field.canClear === true
            ? 'display: inline-block; width: calc(100% - 42px);'
            : ''
        " :data-app="`configMenuItem-${ field.name }-block2`">
          <!-- <v-autocomplete
            @blur="$emit('blur')"
            :items="referenceOptions"
            xfilter="referenceFilter"
            :prefix="field.prefix"
            :suffix="field.suffix"
            :prepend-icon="field.prependIcon"
            :prepend-inner-icon="field.prependInnerIcon"
            :append-icon="field.appendIcon"
            :append-outer-icon="field.appendOuterIcon"
            :counter="field.counter"
            :messages="field.messages"
            :persistent-hint="field.persistentHint"
            :placeholder="field.placeholder"
            :readonly="field.readonly || readonly"
            item-text="name"
            item-value="value"
            v-model="data"
            xplaceholder="Start typing to Search"
            :label="`${field.name}${field.required === true ? ' *' : ''}`"
          ></v-autocomplete> -->
          <!-- <v-select
            @blur="$emit('blur')"
            :prefix="field.prefix"
            :suffix="field.suffix"
            :placeholder="field.placeholder"
            :readonly="field.readonly || readonly"
            v-model="data"
            :label="`${field.name}${field.required === true ? ' *' : ''}`"
            :items="referenceOptions"
            item-text="name"
            item-value="value"
          ></v-select> -->
          <label>{{ field.name }} {{ field.required === true ? " *" : "" }}</label>
          <b-form-select v-model="data" class="mb-3" :readonly="field.readonly || readonly">
            <option :value="null" v-if="field.placeholder">
              {{ field.placeholder }}
            </option>
            <option v-for="item in referenceOptions" v-bind:key="`${ item.name }:${ item.value }`" :value="item.value">
              {{ item.name }}
            </option>
          </b-form-select>
        </div>
        <div v-if="
          field.canClear === true &&
          field.readonly !== true &&
          readonly !== true
        " style="display: inline-block; margin-left: 5px">
          <b-btn @click="data = null" small text>X</b-btn>
        </div>
      </div>
    </template>
    <template v-else-if="field.type.indexOf('reference[') === 0">
      <div :data-app="`configMenuItem-${ field.name }-block3`">
        <!-- <v-autocomplete
          @blur="$emit('blur')"
          :items="referenceOptions"
          :filter="referenceFilter"
          :prefix="field.prefix"
          :suffix="field.suffix"
          :prepend-icon="field.prependIcon"
          :prepend-inner-icon="field.prependInnerIcon"
          :append-icon="field.appendIcon"
          :append-outer-icon="field.appendOuterIcon"
          :counter="field.counter"
          :messages="field.messages"
          :persistent-hint="field.persistentHint"
          :placeholder="field.placeholder"
          :readonly="field.readonly || readonly"
          item-text="name"
          item-value="value"
          v-model="data"
          :label="`${field.name}${field.required === true ? ' *' : ''}`"
        ></v-autocomplete> -->
        <!-- <v-select
          @blur="$emit('blur')"
          :prefix="field.prefix"
          :suffix="field.suffix"
          :placeholder="field.placeholder"
          :readonly="field.readonly || readonly"
          v-model="data"
          :label="`${field.name}${field.required === true ? ' *' : ''}`"
          :items="referenceOptions"
          item-text="name"
          item-value="value"
        ></v-select> -->
        <label>{{ field.name }} {{ field.required === true ? " *" : "" }}</label>
        <b-form-select v-model="data" class="mb-3" :readonly="field.readonly || readonly">
          <option :value="null" v-if="field.placeholder">
            {{ field.placeholder }}
          </option>
          <option v-for="item in referenceOptions" v-bind:key="`${ item.name }:${ item.value }`" :value="item.value">
            {{ item.name }}
          </option>
        </b-form-select>
      </div>
    </template>
    <template v-else>
      <v-text-field :theme="$vuetify.theme" @blur="$emit('blur')" v-model="data" :type="field.type"
        :required="field.required" :hint="field.description" :prefix="field.prefix" :suffix="field.suffix"
        :prepend-icon="field.prependIcon" :prepend-inner-icon="field.prependInnerIcon" :append-icon="field.appendIcon"
        :append-outer-icon="field.appendOuterIcon" :counter="field.counter" :messages="field.messages"
        :persistent-hint="field.persistentHint" :placeholder="field.placeholder" :readonly="field.readonly || readonly"
        :rules="MyRules" :clearable="!(field.readonly || readonly)"
        :label="`${ field.name }${ field.required === true ? ' *' : '' }`"></v-text-field>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
//import Loader from "@/views/partials/content/Loader.vue";
//import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
//import Swal from "sweetalert2";
//import TLib from "./lib";

export default {
  name: "TemplatesItemDefaultDataConfigValue",
  props: ["field", "value", "getDataForPath", "readonly"],
  components: {},
  watch: {
    value() {
      this.$data.data = this.FormatData(this.value);
    },
    data() {
      let mutated = this.FormatData(this.data);
      if (mutated != this.data) {
        let self = this;
        self.$nextTick(() => {
          self.$data.data = mutated;
        });
        return;
      }
      this.$emit("change");
      if (this.field.type.indexOf("rmultiple") === 0) {
        if (!this.$tools.isNullOrUndefined(this.data)) {
          if (this.$tools.isArray(this.data) && this.data.length === 0) {
            this.$data.data = null;
            return;
          }
        }
      }

      this.$emit("input", this.data);
    },
  },
  data() {
    return {
      data: null,

      regexes: {
        ip: /\b(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\b/,
        cidr: /\b(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\/([0-9]|[1-2][0-9]|3[0-2]))\b/,
        mac: /^([0-9A-F]{2}[:-]){5}([0-9A-F]{2})$/,
      },
    };
  },
  methods: {
    FormatIP(pValue) {
      let splitStr = `${ pValue }`.split(".");
      let madeStr = "";
      try {
        let carry = 0;
        if (splitStr.length > 0) {
          let ip1 = Number.parseInt(splitStr[0]);
          if (Number.isNaN(ip1)) {
            madeStr += splitStr[0] === "" ? "." : "";
          } else {
            ip1 += carry;
            carry = 0;
            if (ip1 < 0) {
              madeStr += "000";
            } else if (ip1 > 255) {
              madeStr += "255";
              carry = ip1 - 255;
            } else {
              madeStr += `${ this.$tools.GetNiceNumberFormat(ip1, 3) }`;
            }
          }
        }
        if (splitStr.length > 1) {
          let ip2 = Number.parseInt(splitStr[1]);
          if (Number.isNaN(ip2)) {
            madeStr += splitStr[1] === "" ? "." : "";
          } else {
            ip2 += carry;
            carry = 0;
            if (ip2 < 0) {
              madeStr += ".0";
            } else if (ip2 > 255) {
              madeStr += ".255";
              carry = ip2 - 255;
            } else {
              madeStr += `.${ this.$tools.GetNiceNumberFormat(ip2, 3) }`;
            }
          }
        }
        if (splitStr.length > 2) {
          let ip3 = Number.parseInt(splitStr[2]);
          if (Number.isNaN(ip3)) {
            madeStr += splitStr[2] === "" ? "." : "";
          } else {
            ip3 += carry;
            carry = 0;
            if (ip3 < 0) {
              madeStr += ".0";
            } else if (ip3 > 255) {
              madeStr += ".255";
              carry = ip3 - 255;
            } else {
              madeStr += `.${ this.$tools.GetNiceNumberFormat(ip3, 3) }`;
            }
          }
        }
        if (splitStr.length > 3) {
          let ip4 = Number.parseInt(splitStr[3]);
          if (Number.isNaN(ip4)) {
            madeStr += splitStr[3] === "" ? "." : "";
          } else {
            ip4 += carry;
            carry = 0;
            if (ip4 < 0) {
              madeStr += ".0";
            } else if (ip4 > 255) {
              madeStr += ".255";
              carry = ip4 - 255;
            } else {
              madeStr += `.${ this.$tools.GetNiceNumberFormat(ip4, 3) }`;
            }
          }
        }
      } catch (Exc) {
        //console.error(Exc);
      }
      while (madeStr.indexOf("..") >= 0) {
        madeStr = madeStr.replace("..", ".");
      }
      if (madeStr == ".") madeStr = "";
      return madeStr;
    },
    FormatData(pValue) {
      return pValue; // disable formatting
      /*switch (this.field.type) {
        case "ip":
          return this.FormatIP(pValue);
        case "cidr": {
          let strP = `${pValue}`.split("/");
          let eStr = "";
          if (strP.length > 1) {
            eStr = '/';
            let num = Number.parseInt(strP[1]);
            if (!Number.isNaN(num)) {
              if (num > 32 || num < 0) num = 32;
              eStr = `/${num}`;
            }
          }
          return this.FormatIP(pValue) + eStr;
        }
      }
      return pValue;*/
    },
    AddNewItem() {
      this.$emit(
        "addItem",
        {
          id: this.menuItem.id,
          name: this.menuItem.actualName || this.menuItem.name,
        },
        this.menuItem._list
      );
    },
    referenceFilter(item, queryText) {
      if (this.$tools.isNullOrUndefined(queryText) || queryText == "")
        return true;
      const textOne = item.name.toLowerCase();
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1;
    },
  },
  computed: {
    MyRules() {
      let self = this;
      let listORules = [];
      if (this.field.required === true) {
        listORules.push((v) => {
          if (self.$tools.isNullOrUndefined(v))
            return `${ self.field.name } is required`;
          if (self.field.type === "number") {
            if (v.length < 1) return `${ self.field.name } is required`;
          } else {
            if (v.length < 2) return `${ self.field.name } is required`;
          }
          return null;
        });
      }
      if (this.field.type === "ip") {
        listORules.push((v) => {
          if (self.$tools.isNullOrUndefined(v)) return null;
          if (v == "") return null;
          if (!self.regexes.ip.test(v))
            return `${ self.field.name } is not a valid IP address`;
          return null;
        });
      }
      if (this.field.type === "cidr") {
        listORules.push((v) => {
          if (self.$tools.isNullOrUndefined(v)) return null;
          if (v == "") return null;
          if (!self.regexes.cidr.test(v))
            return `${ self.field.name } is not a valid CIDR address`;
          return null;
        });
      }
      if (this.field.type === "mac") {
        listORules.push((v) => {
          if (self.$tools.isNullOrUndefined(v)) return null;
          if (v == "") return null;
          if (!self.regexes.mac.test(v))
            return `${ self.field.name } is not a valid MAC address`;
          return null;
        });
      }
      return [
        (v) => {
          for (let rule of listORules) {
            let result = rule(v);
            if (!self.$tools.isNull(result)) return result;
          }
          return true;
        },
      ];
    },
    referenceOptions() {
      if (this.field.type.indexOf("[") < 0) {
        if (this.$tools.isNullOrUndefined(this.field.items)) return [];
        let dataList = [];
        for (let value of this.field.items) {
          dataList.push({
            name: value,
            text: value,
            value: value,
          });
        }
        return dataList;
      }
      let temp1 = this.field.type.split("[")[1].split("]")[0].split("|");
      let keyName = temp1.length == 2 ? temp1[0] : "name";
      let references = (temp1.length == 2 ? temp1[1] : temp1[0]).split(",");
      let dataFromReferences = [];

      //console.log(`referenceOptions:`, references);
      for (let referencc of references) {
        let foundData = this.getDataForPath(
          referencc + "._list",
          undefined,
          undefined,
          0
        );
        //console.log(`referenceOptions:${referencc}:${keyName}:`, references, foundData);
        if (!this.$tools.isArray(foundData)) continue;
        for (let founDat of foundData) {
          let value = founDat;
          if (!this.$tools.isNullOrUndefined(founDat[keyName])) {
            if (!this.$tools.isString(value)) value = founDat[keyName];
            dataFromReferences.push({
              name: value,
              text: value,
              value: value,
            });
          } else if (!this.$tools.isNullOrUndefined(founDat.props[keyName])) {
            if (!this.$tools.isString(value)) value = founDat.props[keyName];
            dataFromReferences.push({
              name: value,
              text: value,
              value: value,
            });
          }
        }
      }
      //console.log(`referenceOptions:`, references, dataFromReferences);

      if (
        !this.$tools.isNullOrUndefined(this.field) &&
        !this.$tools.isNullOrUndefined(this.field.items)
      )
        for (let item of this.field.items) {
          dataFromReferences.push(item);
        }

      return dataFromReferences;
    },
    ...mapGetters(["layoutConfig"]),

    config() {
      return this.layoutConfig();
    },
  },
  mounted() {
    if (this.$tools.isNullOrUndefined(this.value) || this.value == "") {
      if (!this.$tools.isNullOrUndefined(this.field.default)) {
        this.$data.data = this.field.default;
        return;
      }
      if (!this.$tools.isNullOrUndefined(this.field.null)) {
        this.$data.data = this.field.null;
        return;
      }
    }
    this.$data.data = this.value;
  },
};
</script>

<style>
.kt-quick-panel__content [data-app="configMenuItem"] .v-menu__content.v-menu__content--fixed.menuable__content__active {
  left: 20px !important;
}
</style>
