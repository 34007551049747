var render = function render(){var _vm=this,_c=_vm._self._c;return _c('KTPortlet',{staticClass:"noBodyPad",attrs:{"title":"View/Edit template"}},[_c('template',{slot:"toolbar"},[_vm._t("saveModal",function(){return [(!_vm.$tools.isNullOrUndefined(_vm.drafts) && _vm.drafts.length > 1)?_c('b-form-select',{staticStyle:{"margin-right":"10px"},attrs:{"options":_vm.drafts},on:{"change":function($event){return _vm.$emit('changeDraft', _vm.selectedDraft)}},model:{value:(_vm.selectedDraft),callback:function ($$v) {_vm.selectedDraft=$$v},expression:"selectedDraft"}}):_vm._e(),(_vm.canDeploy)?[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$emit('deploy', _vm.selectedDraft)}}},[_vm._v("DEPLOY")]),_c('b-button',{staticStyle:{"margin-left":"10px"},attrs:{"variant":"danger"},on:{"click":function($event){return _vm.$emit('delete', _vm.selectedDraft)}}},[_vm._v("DELETE")])]:(_vm.hasChangesToSave)?_c('b-button',{attrs:{"variant":"success"},on:{"click":function($event){return _vm.$emit('save', 1)}}},[_vm._v("SAVE")]):_c('b-button',{staticStyle:{"margin-left":"10px"},attrs:{"variant":"info"},on:{"click":function($event){return _vm.$emit('newTemplate')}}},[_vm._v("New"),_c('span',{staticStyle:{"opacity":"0"}},[_vm._v("_")]),_vm._v("Template")])]},{"dataApp":"templateAp"})],2),_c('template',{slot:"body"},[_c('Loader',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading == true),expression:"loading == true"}],attrs:{"inContent":true}},[_vm._v("Loading...")]),(_vm.menuItems !== null)?_c('TemplatesItemDefaultMenuItem',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading != true),expression:"loading != true"}],attrs:{"menuItems":_vm.menuItems,"stylez":{
        def: { horizontal: true },
        bia: {
          vertical: true,
          headerStyle: 'background: var(--dark); color: #fff;'
        },
        extl: {
          vertical: true,
          headerStyle: 'background: var(--dark); color: #fff;'
        }
      }},scopedSlots:_vm._u([_vm._l((_vm.menuItems),function(menuItem){return {key:`item-${menuItem.id}`,fn:function(slotScope){return _c('div',{key:menuItem.id,staticStyle:{"padding":"10px"}},[_c('MenuData',{attrs:{"menuItem":slotScope.value,"template":_vm.template,"templateItem":_vm.$tools.GetValueFromObjectBasedOnStringPath(
              _vm.template,
              menuItem.id.replace(/\//g, '.')
            ),"templateInfo":_vm.templateInfo,"type":_vm.type,"layout":_vm.layout,"getDataForPath":_vm.getDataForPath},on:{"updateTemplate":(a, b, c) => _vm.$emit('updateTemplate', a, b, c),"addConfigChangeLog":a => _vm.$emit('addConfigChangeLog', a),"addItem":(a, b, c) => _vm.$emit('addItem', a, b, c),"editItem":(a, b, c) => _vm.$emit('editItem', a, b, c),"removeItem":(a, b, c) => _vm.$emit('removeItem', a, b, c),"saveConfigEdit":(a, b, c, d) => _vm.$emit('saveConfigEdit', a, b, c, d),"saveOptionChange":(a, b, c, d) => _vm.saveOptionChange(a, b, c, d)}})],1)}}})],null,true)}):_vm._e()],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }