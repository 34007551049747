import {
  mapGetters
} from "vuex";
import KTPortlet from "../../partials/content/Portlet.vue";
import Loader from "@/views/partials/content/Loader.vue";
import {
  SET_BREADCRUMB
} from "@/store/breadcrumbs.module";
import Swal from "sweetalert2";
import TLib from "./lib";
//import X_Mikrotik_Routerboard from "./templates.item.mikrotik";
import X_DEFAULT from "./templates.item.default";
import Y_SIDEBAR from "../../partials/layout/QuickPanelBase";
import DataConfig from "./templates.item.default.data.config";
import moment from "moment";

export default {
  name: "TemplatesItem",
  components: {
    KTPortlet,
    Loader,
    //X_Mikrotik_Routerboard: X_Mikrotik_Routerboard,
    X_DEFAULT: X_DEFAULT,
    YSIDEBAR: Y_SIDEBAR,
    DataConfig: DataConfig
  },
  watch: {
    workingTemplate: {
      deep: true,
      handler() {
        this.$data.countttt++;
        /*console.log(
          "H:workingTemplate:" +
            this.coreWorkingTemplateTrig +
            " ? " +
            this.$data.countttt
        );*/
        if (!this.coreWorkingTemplateTrig && this.$data.countttt < 10) {
          this.$data.coreWorkingTemplateTrig = true;
          this.$data.workingTemplate = JSON.parse(
            JSON.stringify(this.coreWorkingTemplate)
          );
          return;
        }
        this.$data.coreWorkingTemplateTrig = false;
      }
    },
    coreWorkingTemplate: {
      deep: true,
      handler() {
        this.$data.countttt = 0;
        //console.log("H:coreWorkingTemplate");
        this.$data.coreWorkingTemplateTrig = true;
        this.$data.workingTemplate = JSON.parse(
          JSON.stringify(this.coreWorkingTemplate)
        );
      }
    }
  },
  data() {
    return {
      countttt: 0,
      layout: null,
      devices: null,
      templates: null,
      types: null,
      hierachialTemplate: null,
      workingTemplate: null,
      coreWorkingTemplate: null,
      coreWorkingTemplateTrig: false,
      workingTemplateInfo: null,
      workingType: null,
      workingComponent: null,
      referenceTemplate: null,

      newTemplateObj: null,
      newTemplateObjValid: true,
      newTemplateObjSaving: false,

      addNewItemObj: null,
      addNewItemRefObj: null,
      editItemObj: null,
      editItemRefObj: null,

      resetTimer: 10,
      resetTimerAct: null,
      deleteTimer: 10,
      deleteTimerAct: null,
      deleteModel: null,

      saveDraftName: "",
      saveDraftSlot: false,
      saveDraftValid: false,
      saveDraftRules: [
        value => !!value || "Required.",
        value =>
        (value && value.length >= 3) ||
        `${3 - value.length} more character${
            value.length >= 2 ? "" : "s"
          } required.`
      ],
      saving: false,
      loading: false,

      changesList: [],

      templateConfig: {
        expanded: false,
        templateDeviceValid: true
      },

      deployTemplateObj: null,
      deployTemplateSaving: false,
      deployTemplateValid: true
    };
  },
  methods: {
    deployTemplate(draftId) {
      for (let draft of this.workingTemplateInfo.drafts) {
        if (`${draft.version}` === `${draftId}`) {
          this.$data.deployTemplateObj = draft;
          this.$refs.deploySidebar.show();
          return;
        }
      }
      this.$log.error("err trying to deploy");
    },
    deployTemplateFinal() {
      this.$data.deployTemplateSaving = true;
      let self = this;
      TLib.deployDraft(
          this, self.$route.params.serviceId,
          this.workingTemplateInfo.id,
          this.deployTemplateObj.version
        )
        .then(async () => {
          self.changeDraft(null);
          await this.GetTemplates();
          self.$data.deployTemplateSaving = false;
          self.$refs.deploySidebar.hide();
        })
        .catch(er => {
          self.$log.error(er);
          self.$data.deployTemplateSaving = false;
          self.$log.error("err deploying");
        });
    },
    newTemplate() {
      this.$data.newTemplateObj = {
        name: this.workingTemplateInfo,
        newName: "",
        linkedDeviceId: null
      };
      this.$refs.newTemplateSidebar.show();
    },
    newTemplateObjChange() {
      if (!this.$tools.isNullOrUndefined(this.newTemplateObj.linkedDeviceId)) {
        for (let device of this.devices) {
          if (device.id === this.newTemplateObj.linkedDeviceId) {
            this.$data.newTemplateObj.newName = device.name;
            return;
          }
        }
      }
    },
    newTemplateFinal() {
      this.$data.newTemplateObjSaving = true;
      let self = this;
      TLib.newTemplate(
          this,
          self.$route.params.serviceId,
          this.workingTemplateInfo.id,
          this.newTemplateObj.newName
        )
        .then(async newId => {
          await self.GetTemplates();
          if (
            !self.$tools.isNullOrUndefined(self.newTemplateObj.linkedDeviceId)
          ) {
            let device = await TLib.getDevices(
              self,
              self.$route.params.serviceId,
              self.newTemplateObj.linkedDeviceId
            );
            device.templateId = newId;
            await TLib.saveDevice(
              self,
              self.$route.params.serviceId,
              self.newTemplateObj.linkedDeviceId,
              device
            );
          }
          self.$data.newTemplateObjSaving = false;
          self.$refs.newTemplateSidebar.hide();
          self.$router.push(`/Networks/Templates/${newId}`);
        })
        .catch(er => {
          self.$log.error(er);
          self.$data.newTemplateObjSaving = false;
        });
    },
    resetTemplate() {
      let self = this;
      self.$data.resetTimer = 5;
      self.$refs.ResetTemplateModel.show();
      self.$data.resetTimerAct = setInterval(() => {
        if (self.resetTimer < -10) {
          self.resetCanceled();
          return;
        }
        self.$data.resetTimer--;
      }, 1000);
    },
    resetCanceled() {
      clearInterval(this.resetTimerAct);
      this.$data.resetTimerAct = null;
      this.$data.resetTimer = 10;
      this.$refs.ResetTemplateModel.hide();
    },
    resetTemplateFinal() {
      this.resetCanceled();
      this.$refs.ResetTemplateModel.hide();
      this.GetTemplates();
    },
    deleteTemplate(draft) {
      let self = this;
      self.$data.deleteModel = draft || null;
      self.$data.deleteTimer = 5;
      self.$refs.deleteTemplateModel.show();
      self.$data.deleteTimerAct = setInterval(() => {
        if (self.deleteTimer < -10) {
          self.deleteCanceled();
          return;
        }
        self.$data.deleteTimer--;
      }, 1000);
    },
    deleteCanceled(hideModal = true) {
      if (this.deleteTimerAct === true) {
        let self = this;
        this.$nextTick(() => {
          self.$refs.deleteTemplateModel.show();
        });
        return;
      }
      clearInterval(this.deleteTimerAct);
      this.$data.deleteTimerAct = null;
      this.$data.deleteTimer = 10;
      if (hideModal) this.$refs.deleteTemplateModel.hide();
    },
    async deleteTemplateFinal() {
      this.deleteCanceled(false);
      this.$data.deleteTimerAct = true;
      await TLib.deleteTemplates(
        this,
        self.$route.params.serviceId,
        this.$route.params.templateId,
        this.deleteModel
      );
      this.$data.deleteTimerAct = null;
      this.$refs.deleteTemplateModel.hide();
      if (this.$tools.isNullOrUndefined(this.deleteModel))
        this.$router.push(`/Networks/Templates`);
      else {
        this.$router.push(
          `/Networks/Templates/${this.$route.params.templateId}`
        );
        this.GetTemplates();
      }
    },
    changeDraft(draftId) {
      let self = this;
      self.$data.saving = false;
      self.$refs.SaveTemplateModel.hide();
      self.$refs.sidebar.hide();
      self.$refs.sidebarEdit.hide();
      self.$data.loading = true;
      self.$nextTick(() => {
        self.$router.push(
          `/Networks/Templates/${self.$route.params.templateId}${
            self.$tools.isNullOrUndefined(draftId) ? "" : `/${draftId}`
          }`
        );
        self.$nextTick(() => {
          self.SetTemplate();
        });
      });
    },
    saveTemplateFinal() {
      let self = this;
      self.$refs.SaveTemplateModel.hide();
      self.$data.saving = true;
      //console.log("saveTemplateFinal");
      TLib.setTemplates(
          self,
          self.$route.params.serviceId,
          self.workingTemplateInfo.id,
          self.workingTemplate,
          self.saveDraftName,
          self.$route.params.draftId
        )
        .then(xResp => {
          self.$data.saveDraftSlot = false;
          self.$router.push(
            `/Networks/Templates/${self.$route.params.templateId}${
              self.$tools.isNullOrUndefined(xResp.data.draftVersion)
                ? ""
                : `/${xResp.data.draftVersion}`
            }`
          );
          self.$nextTick(() => {
            self.GetTemplates();
          });
          Swal.fire({
            title: "",
            text: "The draft has been successfully saved",
            icon: "success",
            confirmButtonClass: "btn btn-secondary"
          });
        })
        .catch(x => {
          self.$log.error("ERROR SAVING: ");
          self.$log.error(x);
          self.$data.saving = false;
        });
    },
    saveTemplate(lvl) {
      if (this.$tools.isNullOrUndefined(this.$route.params.draftId)) {
        this.$data.saveDraftName = moment().format("DD-MM-YYYY");
        if (lvl == 1) {
          this.$data.saveDraftValid = true;
          this.$data.saveDraftSlot = true;
          /*this.$nextTick( () => {
            this.$data.saveDraftValid = true;
          } );*/
        } else {
          this.$refs.SaveTemplateModel.show();
        }
      } else this.saveTemplateFinal();
    },
    /*TEST() {
      console.log( ':::::::::::-1' );
      console.log(
        this.getDataForPath( "interface.ethernet._list", undefined, undefined, -1 )
      );
      console.log(
        this.getDataForPath( "interface.ethernet._list", undefined, undefined, 0 )
      );
      console.log(
        this.getDataForPath( "interface.ethernet._list", undefined, undefined, 1 )
      );*/
    /*console.log(this.getDataForPath("interface.interface-list.lists"));
    console.log(this.getDataForPath("interface.ethernet"));*/
    /*console.log("B4=========================================");
    console.log(
      JSON.stringify(this.workingTemplate.ip["dhcp-client"].option)
    );
    console.log("DI=========================================");
    console.log(
      this.getDataForPath(
        "ip.dhcp-client.option",
        undefined,
        undefined,
        false
      )
    );
    console.log("AF=========================================");
    console.log(
      JSON.stringify(this.workingTemplate.ip["dhcp-client"].option)
    );
    console.log("FN=========================================");*/
    /*console.log(
      this.$tools.GetValueFromObjectBasedOnStringPath(
        this.layout,
        "interface"
      )
    );*/
    //},
    getReferenceDataObjects(menuItemOptions, nodefault = false) {
      //console.log(`getReferenceDataObjects:`,menuItemOptions)
      if (
        !this.$tools.isNullOrUndefined(menuItemOptions) &&
        !this.$tools.isNullOrUndefined(menuItemOptions.referenceData)
      ) {
        let outDataList = [];

        let referencess = menuItemOptions.referenceData.split(",");
        if (
          !this.$tools.isNullOrUndefined(menuItemOptions.referenceOptions) &&
          !this.$tools.isNullOrUndefined(
            menuItemOptions.referenceOptions.addPaths
          )
        ) {
          for (let line of menuItemOptions.referenceOptions.addPaths)
            referencess.push(line);
        }
        //console.log(`getReferenceDataObjects:`,menuItemOptions, referencess)
        for (let referencc of referencess) {
          let foundData = this.getDataForPath(
            referencc,
            undefined,
            undefined,
            nodefault
          );
          //console.log(`getReferenceDataObjects:${referencc}:`,menuItemOptions, foundData)
          if (!this.$tools.isArray(foundData)) continue;
          for (let founDat of foundData) {
            outDataList.push(founDat);
          }
        }
        if (
          !this.$tools.isNullOrUndefined(menuItemOptions.referenceOptions) &&
          !this.$tools.isNullOrUndefined(
            menuItemOptions.referenceOptions.removePaths
          )
        ) {
          for (let ix = 0; ix < outDataList.length; ix++) {
            if (
              menuItemOptions.referenceOptions.removePaths.indexOf(
                outDataList[ix]._GDKEY
              ) >= 0
            ) {
              outDataList.splice(ix, 1);
              ix = -1;
            }
          }
        }

        //console.log(`getReferenceDataObjects:out:`,menuItemOptions, outDataList)
        return outDataList;
      }
    },
    makeMenuItem(id, thisObj, oneParentUp) {
      let arr = [];

      let pathsIn = Object.keys(thisObj);
      let hasBaseConfig = false;
      //console.log(`id(${id})=${(pathsIn||[]).join(',')}`)
      for (let i = 0; i < pathsIn.length; i++) {
        let configItem = null;
        if (pathsIn[i] === "_list") configItem = "Data";

        if (configItem == null) continue;
        hasBaseConfig = true;
        if (pathsIn.length > 1) {
          arr.push({
            builtIntoAbove: hasBaseConfig,
            id: `${id}.${pathsIn[i]}`,
            name: configItem,
            actualName: oneParentUp.name,
            parentId: id,
            _list: thisObj._list,
            _options: oneParentUp._options,
            _defaults: oneParentUp._defaults
          });
        }
      }
      //console.log(`id(${id})=${(pathsIn||[]).join(',')}-${arr.length}`)
      for (let iOname of pathsIn) {
        if (iOname.indexOf("_") === 0) continue;
        let thisId = this.$tools.isNullOrUndefined(id) ?
          iOname :
          `${id}.${iOname}`;
        //console.log(thisObj)
        arr.push({
          builtIntoAbove: hasBaseConfig,
          id: thisId,
          name: thisObj[iOname].name,
          parentId: id,
          _list: thisObj[iOname]._list,
          _config: thisObj[iOname]._config,
          _options: thisObj[iOname]._options,
          _defaults: thisObj[iOname]._defaults
        });
        if (
          !this.$tools.isNullOrUndefined(thisObj[iOname]._list) &&
          !hasBaseConfig &&
          !this.$tools.isNullOrUndefined(thisObj[iOname].children)
        ) {
          arr.push({
            builtIntoAbove: true,
            id: `${thisId}._list`,
            //actualName: "Interface List",
            name: `Data`,
            parentId: thisId,
            _list: thisObj[iOname]._list,
            _config: thisObj[iOname]._config,
            _options: thisObj[iOname]._options,
            _defaults: thisObj[iOname]._defaults
          });
        }
        if (!this.$tools.isNullOrUndefined(thisObj[iOname].children)) {
          for (let childName of Object.keys(thisObj[iOname].children)) {
            let cHObj = {};
            cHObj[childName] = thisObj[iOname].children[childName];
            for (let madeItem of this.makeMenuItem(
                thisId,
                cHObj,
                thisObj[iOname]
              ))
              arr.push(madeItem);
          }
        }
      }

      return arr;
    },
    getMenuItemName(id) {
      for (let menuItem of this.menuItems) {
        if (id == menuItem.id) return menuItem.name;
      }
      return null;
    },
    getDataForPath(id, wildcardKey = null, menuItem, defaultOpt = -1) {
      try {
        //console.log(`getDataForPath: ${id}`)
        let defOptNum = !this.$tools.isNullOrUndefined(defaultOpt) &&
          this.$tools.isNumber(defaultOpt) ?
          defaultOpt :
          -1;
        //let showDefault = defOptNum >= 0;
        let idClean = id;
        let addAndJoinItemsWithInner_List = true;
        if (id.endsWith("._list")) {
          idClean = id.replace("._list", "");
          addAndJoinItemsWithInner_List = false;
        }

        if (!this.$tools.isNullOrUndefined(menuItem)) {
          //console.log(`getDataForPath: ${id}: menuItem`)
          return this.getReferenceDataObjects(menuItem._options);
        }

        // get referenced based data
        if (!this.$tools.isNullOrUndefined(this.layout._references)) {
          if (
            !this.$tools.isNullOrUndefined(this.layout._references[idClean])
          ) {
            //console.log(`getDataForPath: ${id}: _references`)
            return this.getReferenceDataObjects(
              this.layout._references[idClean]
            );
          }
        }

        // IF ID CONTAINS A * (WILDCARD) WE NEED TO HANDLE IT AS AN ARRAY INDIVIDUALLY

        let noWildCardID =
          idClean.indexOf("*") >= 0 ?
          idClean.replace(".*", "").replace("/*", "") :
          idClean;

        let baseObject = null;
        //let self = this;
        /*const getInnerObject = ( path, obj ) => {
          if ( self.$tools.isNullOrUndefined( path ) || self.$tools.isNullOrUndefined( obj ) ) return null;
          let pathSplt = path.split( '.' );
          if ( pathSplt.length > 1 ) {
            //if (self.$tools.isArray(obj)) {

            //}
            if ( self.$tools.isObject( obj ) ) {
              return getInnerObject( pathSplt.slice( 1 ).join( '.' ) )
            }
            return null;
          }
          if ( self.$tools.isObject( obj ) ) {
            return obj[ pathSplt[ 0 ] ];
          }
          return null;
        } */

        let pathWithChildren = [];
        let pathWithChildrenSplit = noWildCardID.split(".");
        for (let i = 0; i < pathWithChildrenSplit.length - 1; i++) {
          pathWithChildren.push(pathWithChildrenSplit[i]);
          pathWithChildren.push("children");
        }
        pathWithChildren =
          pathWithChildren.join(".") +
          "." +
          pathWithChildrenSplit[pathWithChildrenSplit.length - 1];
        baseObject = this.$tools.GetValueFromObjectBasedOnStringPath(
          //getInnerObject( noWildCardID, this.layout );
          this.layout,
          pathWithChildren.indexOf(".") === 0 ? noWildCardID : pathWithChildren
        );

        //console.log(`:${idClean}`);
        //console.log(this.layout);
        //console.log( noWildCardID );
        //console.log( pathWithChildren );
        if (idClean.indexOf("*") >= 0) {
          let thisList = [];
          if (
            !this.$tools.isNullOrUndefined(baseObject) &&
            !this.$tools.isNullOrUndefined(baseObject.children)
          ) {
            for (let childName of Object.keys(baseObject.children)) {
              //let childFace = baseObject.children[ childName ]; // Object.keys( child )[ 0 ];

              let childList = this.getDataForPath(
                `${noWildCardID.replace(/\//g, ".")}.${childName}.*`,
                undefined,
                undefined,
                defaultOpt
              );
              childList = childList._list || childList;
              if (this.$tools.isArray(childList)) {
                for (let item of childList) {
                  if (
                    this.$tools.isNullOrUndefined(wildcardKey) ||
                    !this.$tools.isNullOrUndefined(item[wildcardKey || "_DEF"])
                  )
                    thisList.push({
                      _GDKEY: `${noWildCardID}.${childName}`,
                      _MenuName: this.getMenuItemName(
                        `${noWildCardID.replace(/\//g, ".")}.${childName}`
                      ),
                      ...item
                    });
                }
              }
            }

            //console.log(`getDataForPath: ${id}: _list`)
            return thisList._list || thisList;
          } else id = noWildCardID;
        }

        let thisList = [];
        if (defaultOpt !== 1) {
          let hierachailList =
            this.$tools.GetValueFromObjectBasedOnStringPath(
              this.hierachialTemplate,
              id
            ) || [];
          //console.log( 'HIERACHIAL' )
          //console.log( hierachailList )
          thisList =
            this.$tools.GetValueFromObjectBasedOnStringPath(
              this.workingTemplate,
              id
            ) || [];
          if (
            this.$tools.isArray(thisList) &&
            this.$tools.isArray(hierachailList)
          )
            for (let item of hierachailList) {
              thisList.push({
                _ref: "hierarchical",
                ...item
              });
            }
          else thisList = this.$tools.mergeObjects(thisList, hierachailList);
        }

        if (
          defOptNum < 1 &&
          !this.$tools.isNullOrUndefined(this.workingTemplateInfo.type)
        ) {
          if (
            !this.$tools.isNullOrUndefined(this.workingTemplateInfo.type.brand)
          ) {
            // if this is null there is a problem...
            if (
              !this.$tools.isNullOrUndefined(
                this.types[this.workingTemplateInfo.type.brand.toLowerCase()].defaults
              )
            ) {
              let templateData = this.$tools.GetValueFromObjectBasedOnStringPath(
                this.types[this.workingTemplateInfo.type.brand.toLowerCase()].defaults,
                idClean
              );
              if (!this.$tools.isNullOrUndefined(templateData)) {
                let newData = this.$tools.mergeObjects(
                  templateData,
                  thisList._list || thisList
                );
                thisList = newData._list || newData;
              }
            }
            if (
              !this.$tools.isNullOrUndefined(this.workingTemplateInfo.type.make)
            ) {
              // if this is null there is a problem...
              if (
                !this.$tools.isNullOrUndefined(
                  this.types[this.workingTemplateInfo.type.brand.toLowerCase()].makes[
                    this.workingTemplateInfo.type.make.toLowerCase()
                  ].defaults
                )
              ) {
                let templateData = this.$tools.GetValueFromObjectBasedOnStringPath(
                  this.types[this.workingTemplateInfo.type.brand.toLowerCase()].makes[
                    this.workingTemplateInfo.type.make.toLowerCase()
                  ].defaults,
                  idClean
                );
                if (!this.$tools.isNullOrUndefined(templateData)) {
                  let newData = this.$tools.mergeObjects(
                    templateData,
                    thisList._list || thisList
                  );
                  thisList = newData._list || newData;
                }
              }
              if (
                !this.$tools.isNullOrUndefined(
                  this.workingTemplateInfo.type.model
                )
              ) {
                if (
                  !this.$tools.isNullOrUndefined(
                    this.types[this.workingTemplateInfo.type.brand.toLowerCase()].makes[
                      this.workingTemplateInfo.type.make.toLowerCase()
                    ].models[this.workingTemplateInfo.type.model.toLowerCase()].defaults
                  )
                ) {
                  let templateData = this.$tools.GetValueFromObjectBasedOnStringPath(
                    this.types[this.workingTemplateInfo.type.brand.toLowerCase()].makes[
                      this.workingTemplateInfo.type.make.toLowerCase()
                    ].models[this.workingTemplateInfo.type.model.toLowerCase()].defaults,
                    idClean
                  );
                  //console.log('TYPEEE')
                  //console.log(templateData)
                  if (!this.$tools.isNullOrUndefined(templateData)) {
                    let newData = this.$tools.mergeObjects(
                      templateData,
                      thisList._list || thisList
                    );
                    //console.log('TYPEEE2222')
                    //console.log(newData)
                    thisList = newData._list || newData;
                  }
                }
              }
            }
          }
        }

        // if (!this.$tools.isNullOrUndefined(this.workingType)) {
        //   if (!this.$tools.isNullOrUndefined(this.workingType.defaults)) {
        //     let templateData = this.$tools.GetValueFromObjectBasedOnStringPath(
        //       this.workingType.defaults,
        //       id
        //     );
        //     if (!this.$tools.isNullOrUndefined(templateData)) {
        //       let newData = this.$tools.mergeObjects(
        //         templateData._list,
        //         JSON.parse(JSON.stringify(thisList))
        //       );
        //       thisList = newData;
        //     }
        //   }
        // }

        if (
          //showDefault &&
          defOptNum < 1 &&
          !this.$tools.isNullOrUndefined(this.workingTemplateInfo)
        ) {
          if (
            !this.$tools.isNullOrUndefined(
              this.workingTemplateInfo.virtualDevice
            )
          ) {
            let templateData = this.$tools.GetValueFromObjectBasedOnStringPath(
              this.workingTemplateInfo.virtualDevice,
              idClean
            );
            if (!this.$tools.isNullOrUndefined(templateData)) {
              let newData = this.$tools.mergeObjects(
                templateData,
                thisList._list || thisList
              );
              thisList = newData._list || newData;
            }
          }
        }

        if (defOptNum > -1) {
          for (let menuItem of this.menuItems) {
            if (menuItem.id !== idClean) continue;
            //console.log('refIDD::'+tempId)
            //console.log(menuItem)
            //console.log(menuItem._defaults)
            if (this.$tools.isNullOrUndefined(menuItem._defaults)) continue;
            if (this.$tools.isArray(menuItem._defaults)) {
              for (let item of menuItem._defaults) {
                (addAndJoinItemsWithInner_List ?
                  thisList._list || thisList :
                  thisList
                ).push({
                  _GDKEY: idClean,
                  _ref: "hierarchical",
                  ...item
                });
              }
            } else {
              let newData = this.$tools.mergeObjects(
                addAndJoinItemsWithInner_List ?
                thisList._list || thisList :
                thisList,
                menuItem._defaults
              );
              addAndJoinItemsWithInner_List
                ?
                thisList._list || thisList :
                (thisList = newData);
            }
          }
        }

        //console.log('::thisList')
        //console.log(thisList)
        //console.log(`getDataForPath: ${id}: thisList`, thisList)
        return thisList;
      } catch (exc) {
        this.$log.error(exc);
        return null;
      }
    },
    undoLastChange() {
      let lastChange = this.changesList.splice(
        this.changesList.length - 1,
        1
      )[0];
      switch (lastChange.revert) {
        case "remove":
          this.removeItem({
              ...lastChange.menuObj,
              value: lastChange.value
            },
            lastChange.refProps
          );
          return this.changesList.splice(this.changesList.length - 1, 1);
        case "add":
          this.$data.addNewItemRefObj = {
            options: {
              id: lastChange.menuObj.id,
              name: lastChange.menuObj.name,
              props: lastChange.refProps,
              menuObj: lastChange.menuObj
            },
            value: {}
          };
          this.$data.addNewItemObj = JSON.parse(
            JSON.stringify({
              options: {
                id: lastChange.menuObj.id,
                name: lastChange.menuObj.name,
                props: lastChange.refProps
              },
              value: lastChange.value
            })
          );
          this.saveAddItem(lastChange.menuObj, lastChange.refProps);
          return this.changesList.splice(this.changesList.length - 1, 1);
        case "change":
          this.$data.editItemRefObj = {
            options: {
              id: lastChange.menuObj.id,
              name: lastChange.menuObj.name,
              props: lastChange.refProps,
              viewOnly: lastChange.viewOnly || false,
              menuObj: lastChange.menuObj
            },
            value: lastChange.value
          };
          this.$data.editItemObj = JSON.parse(
            JSON.stringify({
              options: {
                id: lastChange.menuObj.id,
                name: lastChange.menuObj.name,
                props: lastChange.refProps
              },
              value: lastChange.valueOld
            })
          );
          this.saveEditItem(lastChange.menuObj, lastChange.refProps);
          return this.changesList.splice(this.changesList.length - 1, 1);
      }
    },
    addItem(menuObj, refProps) {
      //console.log('addItem')
      //console.log(menuObj)
      //console.log(refProps)
      this.$data.addNewItemObj = null;
      this.$data.addNewItemRefObj = null;
      const self = this;
      setTimeout(() => {
        self.$data.addNewItemRefObj = {
          options: {
            id: menuObj.id,
            name: menuObj.name,
            props: refProps,
            menuObj: menuObj
          },
          value: {}
        };
        self.$data.addNewItemObj = JSON.parse(
          JSON.stringify({
            options: {
              id: menuObj.id,
              name: menuObj.name,
              props: refProps
            },
            value: {}
          })
        );
        self.$refs.sidebar.show();
      }, 10);
    },
    saveAddItem() {
      let changes = [];
      for (let propKey of Object.keys(this.addNewItemObj.options.props)) {
        let prop = this.addNewItemObj.options.props[propKey];
        if (prop.keyProp === true || prop.required === true) {
          changes.push(
            `${prop.name}=${this.addNewItemObj.value[propKey] || ""}`
          );
        }
      }
      this.addConfigChangeLog({
        text: `Added new ${this.addNewItemRefObj.options.id} (${changes.join(
          ", "
        )})`,
        value: this.addNewItemObj.value,
        revert: "remove",
        refProps: this.addNewItemRefObj.options.props,
        menuObj: this.addNewItemRefObj.options.menuObj
      });
      this.$refs.deviceRef.addChange(
        this.addNewItemRefObj.options.id,
        this.addNewItemObj.value
      );
      this.$refs.sidebar.hide();
    },
    removeItem(menuObj, refProps) {
      let changes = [];
      for (let propKey of Object.keys(refProps)) {
        let prop = refProps[propKey];
        if (prop.keyProp === true || prop.required === true) {
          changes.push(`${prop.name}=${menuObj.value[propKey] || ""}`);
        }
      }
      this.addConfigChangeLog({
        text: `Removed ${menuObj.id} (${changes.join(", ")})`,
        value: menuObj.value,
        refProps: refProps,
        menuObj: menuObj,
        revert: "add"
      });
      this.$refs.deviceRef.removeItem(menuObj.id, menuObj.value);
    },
    editItem(menuObj, refProps, viewOnly) {
      this.$data.editItemObj = null;
      this.$data.editItemRefObj = null;
      setTimeout(() => {
        this.$data.editItemRefObj = {
          options: {
            id: menuObj.id,
            name: menuObj.name,
            props: refProps,
            viewOnly: viewOnly || false,
            menuObj: menuObj
          },
          value: menuObj.value
        };
        this.$data.editItemObj = JSON.parse(
          JSON.stringify({
            options: {
              id: menuObj.id,
              name: menuObj.name,
              props: refProps
            },
            value: menuObj.value
          })
        );
        this.$refs.sidebarEdit.show();
      }, 10);
    },
    saveEditItem() {
      let changes = [];
      let changesTo = [];
      for (let propKey of Object.keys(this.editItemObj.options.props)) {
        let prop = this.editItemObj.options.props[propKey];
        if (prop.keyProp === true || prop.required === true) {
          if (
            this.editItemRefObj.value[propKey] !=
            this.editItemObj.value[propKey]
          ) {
            changes.push(
              `${prop.name}=${this.editItemRefObj.value[propKey] || ""}`
            );
            changesTo.push(
              `${prop.name}=${this.editItemObj.value[propKey] || ""}`
            );
          } else
            changes.push(
              `${prop.name}=${this.editItemObj.value[propKey] || ""}`
            );
        }
      }
      this.addConfigChangeLog({
        text: `Changed ${this.editItemRefObj.options.id} (${changes.join(
          ", "
        )}) -> (${changesTo.join(", ")})`,
        valueOld: this.editItemRefObj.value,
        value: this.editItemObj.value,
        refProps: this.editItemRefObj.options.props,
        menuObj: this.editItemRefObj.options.menuObj,
        viewOnly: this.editItemRefObj.options.viewOnly,
        revert: "change"
      });
      this.$refs.deviceRef.saveChange(
        this.editItemRefObj.options.id,
        this.editItemObj.value,
        this.editItemRefObj.value
      );
      this.$refs.sidebarEdit.hide();
    },
    saveConfigEdit(id, oldValue, newValue, optionsConfig) {
      let changes = [];
      let changesTo = [];
      for (let propKey of Object.keys(optionsConfig)) {
        let prop = optionsConfig[propKey];
        if (prop.keyProp === true || prop.required === true) {
          if (oldValue[propKey] != newValue[propKey]) {
            changes.push(`${prop.name}=${newValue[propKey] || ""}`);
            changesTo.push(`${prop.name}=${newValue[propKey] || ""}`);
          } else changes.push(`${prop.name}=${newValue[propKey] || ""}`);
        }
      }
      this.addConfigChangeLog({
        text: `Set ${id} (${changes.join(", ")}) -> (${changesTo.join(", ")})`,
        valueOld: oldValue,
        value: newValue,
        refProps: optionsConfig,
        revert: "configChange"
      });
      this.$refs.deviceRef.saveChange(id, newValue, oldValue);
    },
    addConfigChangeLog(obj) {
      obj.time = new Date().getTime();
      this.$data.changesList.push(obj);
    },
    setUpdatedTemplatePathFinder(path /*, refObj*/ , endObj, workingTemplate) {
      //let returnObject = {};
      /*if (!this.$tools.isNullOrUndefined(refObj))
        returnObject = refObj;*/
      let pathSplit = path.split(".");
      let iPath = pathSplit[0];
      if (path.indexOf(".") >= 0) {
        pathSplit.splice(0, 1);
        workingTemplate[iPath] = this.setUpdatedTemplatePathFinder(
          pathSplit.join("."),
          endObj,
          workingTemplate[iPath] || {}
        );
      } else {
        //console.log( 'set: ' + iPath )
        //console.log( workingTemplate[ iPath ] )
        //console.log( endObj )
        workingTemplate[iPath] = endObj;
        //workingTemplate[ `${iPath}-tet` ] = 'ABC';
        //workingTemplate[ `${iPath}-tet2` ] = {
        //  a: true
        //};
        //workingTemplate[ `${iPath}-tet4` ] = [ 'aa' ];
        //workingTemplate[ `${iPath}-tet6` ] = [ {
        //  n: 'aa'
        //}, {
        //  a: true
        //} ];
        //console.log( workingTemplate )
      }
      return workingTemplate;
    },
    updateTemplate(path, obj, extra = true) {
      if (this.$tools.isArray(obj) && extra !== false) {
        for (let i = 0; i < obj.length; i++) {
          if (obj[i]._ref === "hierarchical") {
            obj.splice(i, 1);
            i = -1;
          }
        }
      }
      //console.log( 'updateTemplate: ' + path )
      //console.log( this.coreWorkingTemplate )
      //console.log( obj )
      this.$data.coreWorkingTemplate = this.setUpdatedTemplatePathFinder(
        path,
        obj,
        JSON.parse(JSON.stringify(this.workingTemplate))
      );
      //console.log( this.$data.coreWorkingTemplate )
      //this.reRenderTemplate();
    },
    /*reRenderTemplate() {
      //console.log("UPDATE TEMPLATE");
      this.$data.coreWorkingTemplate = this.setUpdatedTemplatePathFinder(
        path,
        obj,
        JSON.parse(JSON.stringify(this.workingTemplate))
      );
    },*/
    SetComponent() {
      switch (this.workingType.name) {
        case "MIKROTIK": {
          return this.$data.workingComponent = 'X_Mikrotik_Routerboard';
          /*switch (this.workingType.make) {
            case "MIPSBE":
            case "ARM64":
            case "ARM": {
              return this.$data.workingComponent = 'X_Mikrotik_Routerboard';
            }
          }*/
        }
        //break;
      }
      this.$data.changesList = [];
      this.$data.saving = false;
      this.$data.workingComponent = "X_DEFAULT";
      this.UpdateBreadCrumbs();
      this.$data.loading = false;
      this.$data.saveDraftSlot = false;
      if (!this.$tools.isNullOrUndefined(this.$refs.deviceRef)) {
        this.$refs.deviceRef.reRender();
      }
    },
    GetThisItemType() {
      return this.workingTemplateInfo.type;
    },
    SetLayout() {
      let self = this;
      let ttype = self.GetThisItemType();
      TLib.getLayout(
          self, self.$route.params.serviceId,
          ttype.brand,
          ttype.make,
          ttype.model,
          ttype.firmwareVersion,
          this.saving
        )
        .then(data => {
          self.$data.layout = data.config;
          self.SetComponent();
        })
        .catch(x => {
          self.$log.error(x);
        });
    },
    SetType() {
      let ttype = this.GetThisItemType();
      /*if (
        !this.$tools.isNullOrUndefined(ttype) &&
        !this.$tools.isNullOrUndefined(ttype.brand) &&
        !this.$tools.isNullOrUndefined(ttype.make) &&
        !this.$tools.isNullOrUndefined(ttype.model)
      ) {
        this.$data.workingType = this.types[ttype.brand.toLowerCase()].makes[
          ttype.make.toLowerCase()
        ].models[ttype.model.toLowerCase()];
        return this.SetLayout();
      }*/
      this.$data.workingType = this.types[(ttype.brand || '_').toLowerCase()].makes[
        (ttype.make || '_').toLowerCase()
      ].models[(/*ttype.model ||*/ '_').toLowerCase()];
      this.SetLayout();
      /*for (let tType of this.types)
        if (tType.id === this.workingTemplate.routerTypeId) {
          this.$data.workingType = tType;*/

      /*return;
        }

      return this.$router.push("/Networks/Templates");*/
    },
    UpdateBreadCrumbs() {
      let breadCrumb = [{
          title: "BetterNetworks"
        },
        {
          title: "Templates"
        }
      ];
      //console.log( 'set breadcumbs:' + this.$tools.isNullOrUndefined( this.workingTemplateInfo ) + '::' + ( ( this.workingTemplateInfo || {} ).name ) )
      if (!this.$tools.isNullOrUndefined(this.workingTemplateInfo)) {
        breadCrumb.push({
          title: this.workingTemplateInfo.name
        });
        if (!this.$tools.isNullOrUndefined(this.$route.params.draftId)) {
          for (let draft of this.workingTemplateInfo.drafts || []) {
            if (`${draft.version}` == this.$route.params.draftId) {
              breadCrumb.push({
                title: draft.name
              });
              break;
            }
          }
        }
      }
      breadCrumb.push({
        title: "View/Edit"
      });
      //console.log( breadCrumb )
      this.$store.dispatch(SET_BREADCRUMB, breadCrumb);
    },
    SetTemplate() {
      let self = this;
      return new Promise(resolve => {
        for (let template of self.templates) {
          if (template.id === self.$route.params.templateId) {
            self.$data.workingTemplateInfo = template;
            self.$data.workingTemplateInfo.type.model = undefined; // HACK
            self.UpdateBreadCrumbs();
            break;
          }
        }
        TLib.getTemplateConfig(
            self,
            self.$route.params.serviceId,
            self.workingTemplateInfo.activeConfigId).then(async data => {
            //console.log('GOT DATA')
            //console.log(data)
            self.$data.hierachialTemplate = data.built;
            //console.log("xSetTemplate");
            self.$data.coreWorkingTemplate = data.template;
            //console.log(data)
            self.$data.referenceTemplate = JSON.parse(
              JSON.stringify(data.template)
            );
            self.SetType();
            resolve();
          })
          .catch(x => {
            self.$log.error(x);
          });
        /*TLib.getTemplates(
            self,
            self.$route.params.serviceId,
            this.$route.params.templateId +
            (this.$tools.isNullOrUndefined(this.$route.params.draftId) ?
              "" :
              `.${this.$route.params.draftId}`),
            this.saving
          )
          .then(async data => {
            //console.log('GOT DATA')
            //console.log(data)
            self.$data.hierachialTemplate = data.built;
            //console.log("xSetTemplate");
            self.$data.coreWorkingTemplate = data.template;
            self.$data.referenceTemplate = JSON.parse(
              JSON.stringify(data.template)
            );
            self.SetType();
            resolve();
          })
          .catch(x => {
            self.$log.error(x);
          });*/

        //return this.$router.push("/Networks/Templates");
      });
    },
    GetTemplates() {
      let self = this;
      return new Promise(resolve => {
        TLib.getTemplates(self, self.$route.params.serviceId, null, self.saving)
          .then(async data => {
            self.$data.templates = data;
            TLib.getDevices(self, self.$route.params.serviceId)
              .then(devices => {
                self.$data.devices = devices;
              })
              .catch(x => {
                self.$log.error(x);
              });
            await self.SetTemplate();
            resolve();
          })
          .catch(x => {
            self.$log.error(x);
          });
      });
    },
    GetMutatedParents(thisId) {
      let list = [];
      for (let tTemplate of this.templates)
        if (tTemplate.id === thisId) list.push(tTemplate);
      return list;
    },
    GetMutatedObject(thisId) {
      let children = this.GetMutatedParents(thisId);
      for (let i = 0; i < children.length; i++) {
        let iChildren = this.GetMutatedObject(children[i].parentTemplateId);
        if (iChildren.length > 0) children[i].children = iChildren;
      }
      return children;
    }
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    config() {
      return this.layoutConfig();
    },

    menuItems() {
      if (this.$tools.isNullOrUndefined(this.layout)) return null;

      return this.makeMenuItem(null, this.layout, null);
    },

    getAvailableOrLinkedDevices() {
      if (this.$tools.isNullOrUndefined(this.devices)) return null;
      let newList = [];

      for (let device of this.devices) {
        if (
          device.templateId !== this.workingTemplateInfo.id &&
          device.templateId !== null
        )
          continue;
        newList.push(device);
      }

      return newList;
    },

    mutatedTreeViewOfTemplates() {
      return this.GetMutatedObject(this.workingTemplateInfo.id);
    },
    GetRouterBrands() {
      let list = [];
      for (let modl of Object.keys(this.types)) {
        list.push({
          name: modl,
          value: modl
        });
      }
      return list;
    },
    GetRouterMakes() {
      let list = [];
      for (let modl of Object.keys(
          this.types[this.workingTemplateInfo.type.brand].makes
        )) {
        list.push({
          name: modl,
          value: modl
        });
      }
      return list;
    },
    GetRouterModels() {
      let list = [];
      for (let modl of Object.keys(
          this.types[this.workingTemplateInfo.type.brand].makes[
            this.workingTemplateInfo.type.make
          ].models
        )) {
        list.push({
          name: modl,
          value: modl
        });
      }
      return list;
    }
  },
  mounted() {
    let self = this;
    TLib.getTypes(self, self.$route.params.serviceId)
      .then(typex => {
        self.$data.types = typex;
        self.GetTemplates();
      })
      .catch(x => {
        self.$log.error(x);
        //console.error( "Error loading content" );
      });
  }
};