<template>
  <v-tabs
    :horizontal="getStylz.horizontal"
    :vertical="getStylz.vertical"
    :xdark="getStylz.dark"
    :xlight="!getStylz.dark"
    v-model="selectedTABI"
  >
    <v-tab
      :style="getStylz.headerStyle"
      v-for="(menuItem, menuIndex) in items(refId)"
      v-bind:key="menuItem.id"
    >
      <i
        :style="
          `opacity: ${menuIndex === selectedTABI ? 1 : 0}; margin-right: 5px;`
        "
        class="flaticon2-fast-next"
      ></i>
      {{ menuItem.name }}
      <i
        :style="
          `opacity: ${menuIndex === selectedTABI ? 1 : 0}; margin-left: 5px;`
        "
        class="flaticon2-fast-next"
      ></i>
    </v-tab>
    <v-tab-item
      v-for="(iMenuItem, iMenuIndex) in items(refId)"
      v-bind:key="iMenuItem.id"
    >
      <template v-if="loadedIndexes.indexOf(iMenuIndex) >= 0">
        <TemplatesItemDefaultMenuItem
          :zindex="(zindex || 0) + 1"
          v-if="hasLower(iMenuItem.id)"
          :menuItems="menuItems"
          :referenceId="iMenuItem.id"
          :stylez="stylez"
        >
          <div
            :slot="`item-${XImenuItem.id}`"
            v-for="XImenuItem in menuItems"
            v-bind:key="XImenuItem.id"
            :style="getStylz.style"
          >
            <slot
              :name="`item-${XImenuItem.id}`"
              :value="XImenuItem"
              :id="XImenuItem.id"
              :zindex="(zindex || 0) + 2"
              >{{ zindex }}:{{ XImenuItem.name }}</slot
            >
          </div>
          <div slot="extra">
            <slot
              name="extra"
              :value="iMenuItem"
              :id="iMenuItem.id"
              :zindex="(zindex || 0) + 1"
            />
          </div>
        </TemplatesItemDefaultMenuItem>
        <div v-else style="padding: 10px;">
          <slot
            :name="`item-${iMenuItem.id}`"
            :value="iMenuItem"
            :id="iMenuItem.id"
            :zindex="(zindex || 0) + 1"
          ></slot>
        </div>
      </template>
    </v-tab-item>
    <slot
      name="extra"
      v-if="!$tools.isNullOrUndefined(thisItem)"
      :value="thisItem"
      :id="refId"
      :zindex="zindex || 0"
    />
  </v-tabs>
</template>

<script>
import DYNAMIC_ITEM_TEMPLATE from "./templates.item.default.menuItem";

export default {
  name: "TemplatesItemDefaultMenuItem",
  props: ["stylez", "referenceId", "menuItems", "value", "zindex"],
  components: {
    TemplatesItemDefaultMenuItem: DYNAMIC_ITEM_TEMPLATE
  },
  watch: {
    selectedTABI() {
      if (this.loadedIndexes.indexOf(this.selectedTABI) < 0)
        this.$data.loadedIndexes.push(this.selectedTABI);
    }
  },
  data() {
    return {
      selectedTABI: null,
      loadedIndexes: []
    };
  },
  methods: {
    items(groupId) {
      let list = [];

      for (let item of this.menuItems) {
        if (item.parentId === groupId) list.push(item);
      }

      return list;
    },
    hasLower(groupId) {
      return this.items(groupId).length > 0;
    }
  },
  computed: {
    refId() {
      return this.referenceId || null;
    },
    thisItem() {
      for (let item of this.menuItems) {
        if (item.parentId === this.refId) return item;
      }
      return null;
    },
    getStylz() {
      if ((this.thisItem || {}).builtIntoAbove)
        return (this.stylez || {})["bia"] || {};
      return (this.stylez || {})["def"] || {};
    },
    getStylzI() {
      return (this.stylez || {})["extl"] || {};
    }
  },
  mounted() {
    let iItems = this.items(this.refId);
    if (iItems.length > 0) this.$data.selectedTABI = iItems[0];
  }
};
</script>
