var render = function render(){var _vm=this,_c=_vm._self._c;return (
  !_vm.$tools.isNullOrUndefined(_vm.field) && !_vm.$tools.isNullOrUndefined(_vm.field.type)
)?_c('div',{attrs:{"data-app":`configMenuItem-${ _vm.field.name }`}},[(_vm.field.type === 'readonly')?[_c('v-text-field',{attrs:{"type":"text","prefix":_vm.field.prefix,"suffix":_vm.field.suffix,"prepend-icon":_vm.field.prependIcon,"prepend-inner-icon":_vm.field.prependInnerIcon,"append-icon":_vm.field.appendIcon,"append-outer-icon":_vm.field.appendOuterIcon,"counter":_vm.field.counter,"messages":_vm.field.messages,"persistent-hint":_vm.field.persistentHint,"placeholder":_vm.field.placeholder,"readonly":true,"hint":_vm.field.description,"label":`${ _vm.field.name }${ _vm.field.required === true ? ' *' : '' }`},model:{value:(_vm.data),callback:function ($$v) {_vm.data=$$v},expression:"data"}})]:(['roboolean', 'boolean'].indexOf(_vm.field.type) >= 0)?[_c('v-checkbox',{attrs:{"readonly":_vm.field.type === 'roboolean' || _vm.readonly,"label":`${ _vm.field.name }${ _vm.field.required === true ? ' *' : '' }`},on:{"blur":function($event){return _vm.$emit('blur')}},model:{value:(_vm.data),callback:function ($$v) {_vm.data=$$v},expression:"data"}})]:(_vm.field.type.indexOf('multiple') >= 0)?[(_vm.field.type.indexOf('rmultiple') === 0)?_c('div',[_c('b-form-group',{staticStyle:{"margin-top":"2rem"}},[_c('div',{attrs:{"slot":"label"},slot:"label"},[_vm._v(" "+_vm._s(_vm.field.name)+" "),(_vm.field.readonly !== true && _vm.readonly !== true)?_c('v-btn',{attrs:{"text":"","small":""},on:{"click":function($event){_vm.$tools.isArray(_vm.data) ? _vm.data.push('') : (_vm.data = [''])}}},[_c('i',{staticClass:"flaticon2-plus-1 v-size--med"})]):_vm._e()],1),(
          _vm.$tools.isNullOrUndefined(_vm.data) ||
          !_vm.$tools.isArray(_vm.data) ||
          _vm.data.length === 0
        )?_c('div'):_vm._l((_vm.data || []),function(row,i){return _c('div',{key:row},[_c('div',{staticStyle:{"display":"inline-block","width":"calc(100% - 42px)"},attrs:{"data-app":`configMenuItem-${ _vm.field.name }-block1`}},[_c('label',[_vm._v(_vm._s(_vm.field.name)+" "+_vm._s(_vm.field.required === true ? " *" : ""))]),_c('b-form-select',{staticClass:"mb-3",attrs:{"readonly":_vm.field.readonly || _vm.readonly},model:{value:(_vm.data[i]),callback:function ($$v) {_vm.$set(_vm.data, i, $$v)},expression:"data[i]"}},[(_vm.field.placeholder)?_c('option',{domProps:{"value":null}},[_vm._v(" "+_vm._s(_vm.field.placeholder)+" ")]):_vm._e(),_vm._l((_vm.referenceOptions),function(item){return _c('option',{key:`${ item.name }:${ item.value }`,domProps:{"value":item.value}},[_vm._v(" "+_vm._s(item.name)+" ")])})],2)],1),(_vm.field.readonly !== true && !_vm.readonly)?_c('div',{staticStyle:{"display":"inline-block","margin-left":"5px"}},[_c('b-btn',{attrs:{"small":"","text":""},on:{"click":function($event){return _vm.data.splice(i, 1)}}},[_vm._v("X")])],1):_vm._e()])})],2)],1):_c('div',[_c('div',{style:(_vm.field.canClear === true
          ? 'display: inline-block; width: calc(100% - 42px);'
          : ''),attrs:{"data-app":`configMenuItem-${ _vm.field.name }-block2`}},[_c('label',[_vm._v(_vm._s(_vm.field.name)+" "+_vm._s(_vm.field.required === true ? " *" : ""))]),_c('b-form-select',{staticClass:"mb-3",attrs:{"readonly":_vm.field.readonly || _vm.readonly},model:{value:(_vm.data),callback:function ($$v) {_vm.data=$$v},expression:"data"}},[(_vm.field.placeholder)?_c('option',{domProps:{"value":null}},[_vm._v(" "+_vm._s(_vm.field.placeholder)+" ")]):_vm._e(),_vm._l((_vm.referenceOptions),function(item){return _c('option',{key:`${ item.name }:${ item.value }`,domProps:{"value":item.value}},[_vm._v(" "+_vm._s(item.name)+" ")])})],2)],1),(
        _vm.field.canClear === true &&
        _vm.field.readonly !== true &&
        _vm.readonly !== true
      )?_c('div',{staticStyle:{"display":"inline-block","margin-left":"5px"}},[_c('b-btn',{attrs:{"small":"","text":""},on:{"click":function($event){_vm.data = null}}},[_vm._v("X")])],1):_vm._e()])]:(_vm.field.type.indexOf('reference[') === 0)?[_c('div',{attrs:{"data-app":`configMenuItem-${ _vm.field.name }-block3`}},[_c('label',[_vm._v(_vm._s(_vm.field.name)+" "+_vm._s(_vm.field.required === true ? " *" : ""))]),_c('b-form-select',{staticClass:"mb-3",attrs:{"readonly":_vm.field.readonly || _vm.readonly},model:{value:(_vm.data),callback:function ($$v) {_vm.data=$$v},expression:"data"}},[(_vm.field.placeholder)?_c('option',{domProps:{"value":null}},[_vm._v(" "+_vm._s(_vm.field.placeholder)+" ")]):_vm._e(),_vm._l((_vm.referenceOptions),function(item){return _c('option',{key:`${ item.name }:${ item.value }`,domProps:{"value":item.value}},[_vm._v(" "+_vm._s(item.name)+" ")])})],2)],1)]:[_c('v-text-field',{attrs:{"theme":_vm.$vuetify.theme,"type":_vm.field.type,"required":_vm.field.required,"hint":_vm.field.description,"prefix":_vm.field.prefix,"suffix":_vm.field.suffix,"prepend-icon":_vm.field.prependIcon,"prepend-inner-icon":_vm.field.prependInnerIcon,"append-icon":_vm.field.appendIcon,"append-outer-icon":_vm.field.appendOuterIcon,"counter":_vm.field.counter,"messages":_vm.field.messages,"persistent-hint":_vm.field.persistentHint,"placeholder":_vm.field.placeholder,"readonly":_vm.field.readonly || _vm.readonly,"rules":_vm.MyRules,"clearable":!(_vm.field.readonly || _vm.readonly),"label":`${ _vm.field.name }${ _vm.field.required === true ? ' *' : '' }`},on:{"blur":function($event){return _vm.$emit('blur')}},model:{value:(_vm.data),callback:function ($$v) {_vm.data=$$v},expression:"data"}})]],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }